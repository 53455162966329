import { Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Paper, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { AuthApi, BankAccountApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object().shape({
  pin: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
};

type Props = {
  action: any[];
  onCancel: (e: any) => void;
  title: string;
  subTitle: string;
  reload?: any;
  change?: any;
};

export function AccountSettingsPinForm({ action, onCancel, title, subTitle, reload, change }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    pin: "",
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin(data);

      if (change && change[0] === "confirm") {
        await BankAccountApi.verify(action[1].id, { isVerified: true });
      } else {
        await BankAccountApi.verify(action[1].id, { isVerified: false });
      }
      reload();
      setLoading(false);
      Message.success("Та B2B Харилцаанд ашиглах данс амжилттай баталгаажууллаа.");
      onCancel && onCancel([]);
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <>
            <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel([])} />]}>
              <LoadingOverlay visible={loading} />
              <Paper>
                <Grid>
                  <Grid.Col span={12}>
                    <Divider />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text component="p" fz="sm" fw={500}>
                      Та бизнесийн төлбөр тооцоонд ашиглах банк данс холбож байна.
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <OtpField name="pin" label="Үйлдлийг баталгаажуулах бол ПИН кодоо оруулна уу." placeholder="" required />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Text component="p" fz="sm" fw={500}>
                      Танд амжилт хүсье!
                    </Text>
                  </Grid.Col>
                </Grid>
                <Flex mt={20} direction="row" justify="space-between">
                  <Button variant="default" onClick={() => onCancel && onCancel([])}>
                    Болих
                  </Button>
                  <Flex gap="md">
                    <Button variant="outline" type="submit">
                      Хадгалах
                    </Button>
                  </Flex>
                </Flex>
              </Paper>
            </FormLayout>
          </>
        );
      }}
    </Form>
  );
}
