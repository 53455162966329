import React from "react";
import { ColumnType, Table } from "../table";
import { RepaymentApi } from "../../apis";
import { Badge, Button, Drawer, Flex, Group, SegmentedControl, Text, Tooltip } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import { IRepayment } from "../../interfaces/IRepayment";
import { ScfRepaymentDetail } from "../../pages/scf/repayment/detail";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import { IReference } from "../../models/General";
import { PageFilter } from "../layout";
import { useDebouncedValue } from "@mantine/hooks";

const res = [
  {
    name: "Бүгд",
    code: "",
    color: "yellow",
  },
  {
    name: "Buyer",
    code: "BUYER",
    color: "green",
  },
  {
    name: "Supplier",
    code: "SUPPLIER",
    color: "blue",
  },
];

export const ScfFundingRepaymentList = () => {
  const [action, setAction] = React.useState<string[]>([]);
  const { repaymentOverDueStatus, repaymentPaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [status, setStatus] = React.useState("");
  const [statusDebounced] = useDebouncedValue(status, 400);

  const columns = useHeader({
    tugrug,
    repaymentPaymentStatus,
    repaymentOverDueStatus,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["information", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageFilter
        left={[
          <Flex justify="flex-start" align="flex-start" direction="column" wrap="wrap" key={1}>
            <Text size={14} color="#868E96">
              Олголтын төрөл
            </Text>
            <SegmentedControl
              onChange={(e) => setStatus(e)}
              value={status}
              data={(res || []).map((item: IReference, index: number) => {
                return {
                  value: item.code || "",
                  label: (
                    <Badge variant="dot" color={item.color} style={{ border: "none" }} key={`${index}`}>
                      {item.name}
                    </Badge>
                  ),
                };
              })}
            />
          </Flex>,
        ]}
      />

      <Table
        name="scf.scffunding.repayment.list"
        columns={columns}
        filters={{ productType: "BUYER_LED", type: statusDebounced, isRecalled: `${false}` }}
        loadData={(data) => RepaymentApi.list(data!)}
      />
      <Drawer
        opened={action[0] === "information"}
        onClose={() => onCancel && onCancel()}
        position="right"
        withCloseButton={false}
        size="80%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <ScfRepaymentDetail action={action} onCancel={onCancel} />
      </Drawer>
    </>
  );
};

const useHeader = ({
  onClick,
  tugrug,
  repaymentPaymentStatus,
  repaymentOverDueStatus,
}: {
  onClick: (key: string, record: IRepayment) => void;
  tugrug: any;
  repaymentOverDueStatus: IReference[];
  repaymentPaymentStatus: IReference[];
}): ColumnType<IRepayment>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "ЭТН код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "ЭТН төлөх дүн",
    sorter: true,
    dataIndex: "repaymentAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.repaymentAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "paidAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
            {tugrug(record.paidAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    sorter: true,
    dataIndex: "amountToPay",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.amountToPay || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Төлбөрийн төлөв",
    sorter: true,
    dataIndex: "paymentStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentPaymentStatus || []).find((c: IReference) => c?.code === record.paymentStatus)?.color} 1px solid`,
            color: `${(repaymentPaymentStatus || []).find((c: IReference) => c?.code === record.paymentStatus)?.color}`,
          }}>
          {(repaymentPaymentStatus || []).find((c: IReference) => c?.code === record.paymentStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Хугацаа хэтрэлт",
    sorter: true,
    dataIndex: "overDueStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(repaymentOverDueStatus || []).find((item: IReference) => item.code === record?.overdueStatus)?.color} 1px solid`,
            color: `${(repaymentOverDueStatus || []).find((item: IReference) => item.code === record?.overdueStatus)?.color}`,
          }}>
          {(repaymentOverDueStatus || []).find((c: any) => c.code === record?.overdueStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "invRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт код",
    sorter: true,
    dataIndex: "scfRequestRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.scfRequestRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Олголт код",
    sorter: true,
    dataIndex: "disbursementCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.disbursementCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үүсэгсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэгч тал",
    sorter: true,
    dataIndex: "receiver",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.receiver || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүлээж буй хугацаа",
    sorter: true,
    dataIndex: "remainingDays",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.remainingDays || "0"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    sorter: true,
    dataIndex: "invDueDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.invDueDate)}
        </Text>
      );
    },
  },
  {
    title: "Буцаан дуудах",
    sorter: true,
    dataIndex: "recourseTerm",
    render: (record) => {
      return (
        <Badge radius="sm" color={record?.recourseTerm ? "green" : "red"}>
          {record?.recourseTerm ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Алданги",
    sorter: true,
    dataIndex: "penaltyPercent",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.penaltyPercent || "0"}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.payerBusiness?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.payerBusiness?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.payerBusiness?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.payerBusiness?.partner?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.payerBusiness?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт авах данс",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.receiverAcc?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөлт татах данс",
    sorter: true,
    dataIndex: "payerAcc",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.payerAcc?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.payerAcc?.number || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.receiverAcc?.bankName || "-"}
        </Text>
      );
    },
  },
];
