import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { AuthApi, ProgramApi } from "../../apis";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: any;
};

export function SupplierProgramPinForm({ action, onCancel, title, subTitle }: { action: any[]; onCancel: (e: []) => void; title: string; subTitle: string }) {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const [data] = React.useState<IFormData>({
    pin: "",
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: value?.pin });

      if (action && action[2] === "update") {
        await ProgramApi.supplierUpdate(params.id as string, {
          type: "CURRENT_ASSET",
          productType: "SUPPLIER_LED",
          name: action && action[1]?.name,
          resolutionNo: action && action[1]?.resolutionNo,
          description: action && action[1]?.description,
          businessId: action && action[1]?.anchor?.businessId,
          finUserId: action && action[1]?.anchor?.finUser?.id,
          inAccId: action && action[1]?.anchor?.inAccId,
          programLimit: action && action[1]?.programLimit,
          financeStartDate: action && action[1]?.financeStartDate,
          financePeriod: Number(action && action[1]?.financePeriod),
          depositFeeDays: Number(action && action[1]?.depositFeeDays),
          depositFee: action && action[1]?.depositFee,
          programFee: action && action[1]?.programFee,
          disburseAccId: action && action[1]?.disburseAccId,
          repayAccId: action && action[1]?.repayAccId,
          rechargeable: action && action[1]?.rechargeable,
          feeAccId: action && action[1]?.feeAccId,
          otherFeeAccId: action && action[1]?.otherFeeAccId,
          programSector1Ids: (action && action[1]?.programSector1Ids) || [],
          programSector2Ids: [],
          programSector3Ids: [],
          toReview: true,
        });
      } else {
        await ProgramApi.supplierCreate({
          type: "CURRENT_ASSET",
          productType: "SUPPLIER_LED",
          productId: action && action[2],
          name: action && action[1].name,
          resolutionNo: action && action[1].resolutionNo,
          description: action && action[1].description,
          businessId: action && action[1].businessId,
          finUserId: action && action[1]?.anchor?.finUser?.id,
          inAccId: action && action[1].inAccId,
          programLimit: action && action[1].programLimit,
          financeStartDate: action && action[1].financeStartDate,
          financePeriod: Number(action && action[1].financePeriod),
          depositFee: action && action[1].depositFee,
          depositFeeDays: Number(action && action[1].depositFeeDays),
          programFee: action && action[1].programFee,
          disburseAccId: action && action[1].disburseAccId,
          repayAccId: action && action[1].repayAccId,
          rechargeable: action && action[1].rechargeable,
          feeAccId: action && action[1].feeAccId,
          otherFeeAccId: action && action[1].otherFeeAccId,
          programSector1Ids: (action && action[1]?.programSector1Ids) || [],
          programSector2Ids: [],
          programSector3Ids: [],
          toReview: true,
        });
      }
      onCancel([]);
      Message.success(`Та SCF хөтөлбөр амжилттай бүртгэж хяналтад илгээлээ. ${dateTimeFormat(new Date())}`);
      navigate(`/scf-dcf/scf-program`);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel([])} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12}>
                <Divider />
                <Alert mt="sm" styles={{ message: { color: "#44566c", fontWeight: 500 } }} color="indigo" withCloseButton={false} title="Хөтөлбөр">
                  {`Та supplier led бүтээгдэхүүний төрөлтэй хөтөлбөрийг хяналтад илгээх гэж байна.`}
                </Alert>
              </Grid.Col>
              <Grid.Col span={12}>
                <OtpField name="pin" label="Баталгаажуулах ПИН код" placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
            </Grid>
            <Divider my="lg" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel && onCancel([])}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit">
                  Баталгаажуулах
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
