import { ActionIcon, Avatar, Badge, Button, CloseButton, Flex, Grid, Input, LoadingOverlay, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { IconBrandTelegram, IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconTrash } from "@tabler/icons";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { ParticipantApi, ProgramApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IParticipant } from "../../interfaces/IParticipant";
import { IPermissions } from "../../interfaces/IPermissions";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { BuyerLedProgramReviewCustomerEditForm } from "./form";
import { BuyerLedProgramReviewSmeForm } from "./review-sme";
import { BuyerLedSmeForm } from "./sme-form";

const schema = yup.object({});

export function BuyerLedProgramReviewCustomerForm({ data, reload }: { data: IScfProgram; reload: () => void }) {
  const params = useParams();
  const ref = useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = React.useState<string[]>([]);
  const { programParticipantStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const columns = useHeader({
    user,
    programParticipantStatus,
    onClick: (key: string, item: any) => {
      switch (key) {
        case "edit": {
          setAction(["edit", item]);
          break;
        }
        case "review_sme": {
          setAction([key, item]);
          break;
        }
        case "to_review": {
          Dialog.confirm("Та уг бизнесийг хяналтанд илгээхдээ итгэлтэй байна уу. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.toReview({ programId: item.programId, smeBusinessId: item.smeBusinessId });
                  Message.success("Амжилттай хяналтад илгээлээ.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                load();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "active": {
          Dialog.confirm("Та уг бизнесийг идэвхгүй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.status({ programId: item.programId, smeBusinessId: item.smeBusinessId, isActive: false });
                  Message.success("Амжилттай идэвхгүй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                load();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "in_active": {
          Dialog.confirm("Та уг бизнесийг идэвхтэй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.status({ programId: item.programId, smeBusinessId: item.smeBusinessId, isActive: true });
                  Message.success("Амжилттай идэвхтэй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                load();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }

        case "remove": {
          Dialog.confirm("Та уг бизнесийг устгахдаа илгээхдээ итгэлтэй байна уу. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.remove(params?.id as string, item.smeBusinessId);
                  Message.success("Амжилттай устгасан.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                load();
                setIsLoading(false);
                break;
              }

              default:
            }
          });

          break;
        }
      }
    },
  });

  const onSubmit = async () => {
    try {
      Message.success(`Таны хүсэлт амжилттай . ${dateTimeFormat(new Date())}`);
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  };

  const load = async () => {
    ref.current?.reload();
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {() => {
          return (
            <>
              <Paper radius="sm" p="lg" withBorder mt="md">
                <Grid>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Партнерийн нэр">
                      <Text color="indigo">
                        {data?.anchor?.partner?.businessName || "-"}, {data?.anchor?.partner?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөрт хамрагдах бизнесийн нэр">
                      <Text color="indigo">
                        {data?.anchor?.profileName || "-"}, {data?.anchor?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөр дэх анкорын роль">
                      <Text color="indigo">{data?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              </Paper>
              <FormLayout
                title="Нийлүүлэгчид"
                subTitle="Анкор бизнестэй харилцдаг нийлүүлэгчид"
                extra={[
                  <>
                    {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isCreate).length > 0 && (
                      <Button
                        variant="light"
                        onClick={() => {
                          setAction(["sme_add", data as any]);
                        }}
                        key="sme_add">
                        Нийлүүлэгч нэмэх
                      </Button>
                    )}
                  </>,
                ]}>
                <Paper radius="sm" p="lg" withBorder>
                  <Table
                    ref={ref}
                    name="scf.sme.participants.reviews"
                    filters={{ productType: "BUYER_LED", programId: data?.id }}
                    columns={columns}
                    loadData={(data) => ProgramApi.participantList(data!)}
                  />
                </Paper>
              </FormLayout>
            </>
          );
        }}
      </Form>
      <Modal opened={action[0] === "review_sme"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="SME Харилцагчидийн мэдээлэл хянах"
          subTitle="Supply Chain Finance хөтөлбөрийн SME мэдээлэл хянах"
          my={0}
          extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
          <BuyerLedProgramReviewSmeForm
            reload={reload}
            action={action}
            onCancel={() => {
              setAction([]);
            }}
          />
        </FormLayout>
      </Modal>
      <Modal opened={action[0] === "inactive" || action[0] === "active"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FormLayout
          title="SME Харилцагчийг идэвхгүй болгох"
          subTitle="SME Харилцагчийг идэвхгүй болгох"
          my={0}
          extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
          <Paper>
            <BuyerLedProgramReviewSmeForm
              reload={reload}
              action={action}
              onCancel={() => {
                setAction([]);
              }}
            />
          </Paper>
        </FormLayout>
      </Modal>
      <Modal opened={action[0] === "sme_add"} onClose={() => setAction([])} withCloseButton={false} size="60%" centered>
        <BuyerLedSmeForm
          action={action}
          setAction={setAction}
          reload={() => {
            reload();
          }}
        />
      </Modal>
      <Modal
        opened={action[0] === "edit"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        centered
        size="60%">
        <BuyerLedProgramReviewCustomerEditForm
          action={action}
          onCancel={() => {
            setAction([]);
          }}
          reload={() => {
            reload();
            setAction([]);
          }}
        />
      </Modal>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  programParticipantStatus,
}: {
  user: any;
  onClick: (key: string, record: IParticipant) => void;
  programParticipantStatus: any;
}): ColumnType<IParticipant>[] => [
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex gap="8px">
          {record?.participantStatus === "REGISTERED" && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                <IconEdit />
              </Button>
            </Tooltip>
          )}
          {record?.registrationStatus !== "REJECTED" && record?.registrationStatus !== "DRAFT" && record?.participantStatus === "IN_REVIEW" && (
            <Tooltip label="Хянах" position="top">
              <ActionIcon
                c="golomt"
                onClick={() => {
                  onClick("review_sme", record);
                }}>
                <IconEyeCheck />
              </ActionIcon>
            </Tooltip>
          )}

          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isEdit).length >
              0 &&
              record?.participantStatus === "REGISTERED" &&
              record?.programStatus !== "CLOSED" &&
              record?.programStatus !== "CANCELLED" &&
              record?.registrationStatus !== "DRAFT" &&
              record?.registrationStatus !== "REJECTED" && (
                <Tooltip label="Хяналтад илгээх" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("to_review", record)}>
                    <IconBrandTelegram />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_BL").length > 0 &&
              record?.participantStatus === "REVIEWED" &&
              record?.programStatus === "ACTIVE" &&
              record?.participantStatus === "INACTIVE" &&
              record?.registrationStatus === "REVIEWED" && (
                <Tooltip label="Идэвхтэй болгох" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                    <IconCircleCheck />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_BL").length > 0 &&
              record?.participantStatus === "REVIEWED" &&
              record?.programStatus === "ACTIVE" &&
              record?.participantStatus === "ACTIVE" &&
              record?.registrationStatus === "REVIEWED" && (
                <Tooltip label="Идэвхгүй болгох" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                    <IconCircleMinus />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isDelete).length >
              0 && record?.participantStatus === "REGISTERED" ? (
              <Tooltip label="Устгах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
                  <IconTrash />
                </Button>
              </Tooltip>
            ) : (
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => {}}>
                <IconTrash color="#968686" />
              </Button>
            )}
          </>
          {}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "ТТД",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(programParticipantStatus || []).find((c: any) => c?.code === record.participantStatus)?.color} 1px solid`,
            color: `${(programParticipantStatus || []).find((c: any) => c?.code === record.participantStatus)?.color}`,
          }}>
          {programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Партнер нэр",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.partner?.logo !== null ? record?.partner?.logo : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.partner?.refCode || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.partner?.businessName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес нэр",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч санхүү",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={280}>
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.finUser?.lastName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.firstName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч дэд лимит",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeInAcc?.icon === null || !record?.smeInAcc ? noimage : record?.smeInAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.smeInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeOutAcc?.icon === null || !record?.smeOutAcc ? noimage : record?.smeOutAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.smeOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorInAcc?.icon === null || !record?.anchorInAcc ? noimage : record?.anchorInAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.anchorInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorOutAcc?.icon === null || !record?.anchorOutAcc ? noimage : record?.anchorOutAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.anchorOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
];
