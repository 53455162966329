import { LoadingOverlay, Paper, Tabs, Text } from "@mantine/core";
import { IconArticle, IconClipboardText } from "@tabler/icons";
import React from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { RequestApi } from "../../apis";
import { PageLayout } from "../layout";
import { ScfFundingSupplierLedInformationForm } from "./information-form";
import { ScfFundingSupplierLedRequestList } from "./request-list";

export function ScfFundingProvidedSupplierLedTabs() {
  const params = useParams();
  const { id, step } = params;
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(step || "1");

  const { data: requestData } = useSwr<any>(`table.scf-funding-provided.led.${params.id}`, async () => await RequestApi.getSupplier(`${id}`));

  const onTabChange = (_index: any) => {
    navigate(`/scf/funding-provided/SUPPLIER/${params.id}/${_index}`);
    setTabIndex(_index);
  };

  return (
    <PageLayout
      title={`${requestData?.anchor?.partner?.refCode}, ${requestData?.anchor?.partner?.businessName}`}
      subTitle={`${requestData?.anchor?.refCode}# ${requestData?.anchor?.profileName}, ${requestData?.anchor?.regNumber}, supplier led хүсэлтийн жагсаалт`}
      breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <LoadingOverlay visible={!requestData} />
        {requestData && (
          <Tabs defaultValue="1" value={tabIndex} onTabChange={onTabChange}>
            <Tabs.List>
              <Tabs.Tab value="1" icon={<IconArticle color={tabIndex === "1" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Үндсэн мэдээлэл
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="2" icon={<IconClipboardText color={tabIndex === "2" ? "#317169" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Хүсэлтүүд
                </Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="1">
              {tabIndex === "1" && requestData && <ScfFundingSupplierLedInformationForm setIndex={onTabChange} data={requestData} />}
            </Tabs.Panel>
            <Tabs.Panel value="2">
              {tabIndex === "2" && requestData && <ScfFundingSupplierLedRequestList setIndex={setTabIndex} data={requestData} />}
            </Tabs.Panel>
          </Tabs>
        )}
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "SCF санхүүжилт",
  },
  {
    to: "/scf/funding-provided/SUPPLIER",
    label: "Олгосон санхүүжилт",
  },
  {
    label: "Шийдвэрлэсэн хүсэлтүүд",
  },
];
