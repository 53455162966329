/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, createStyles, Image, Text } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useSWR from "swr";
import { AuthApi } from "../../apis";
import Background from "../../assets/background-blur.svg";
import { CreatePassword } from "../../components/auth/create-password";
import { VerifyForm } from "../../components/auth/verify";
import Layout from "../../components/home/layout";
import { authChange, authMe } from "../../store/Auth";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";

export function Verify() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { sessionScope, accessToken } = useSelector((state: any) => state.auth);
  const { id } = useParams();
  const [error, setError] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const { data: verify } = useSWR<any>(
    "swr.auth.verify",
    async () => {
      setLoading(true);
      try {
        if (accessToken && sessionScope === "AUTHORIZED") {
          const res = await AuthApi.verify(id as string);
          if (res?.sessionScope === "FORGOT") {
            await AuthApi.logout();
            const removeToken = { accessToken: null };
            const removeUser = {};
            dispatch(authChange(removeToken as any));
            dispatch(authMe(removeUser as any));
          }

          dispatch(authChange(res as any));
          setLoading(false);
        } else {
          const res = await AuthApi.verify(id as string);
          dispatch(authChange(res as any));
          setLoading(false);
        }
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
        setLoading(false);
        setError(true);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      {sessionScope === "FORGOT" && (
        <div className={classes.container}>
          <VerifyForm />
        </div>
      )}
      {sessionScope === "CHANGE_PASSWORD" && (
        <div className={classes.container}>
          <CreatePassword />
        </div>
      )}
      {error && (
        <div className={classes.container}>
          <Box className={classes.card} sx={() => ({})}>
            <Text align="center" color="red" fw={700} fz={24}>
              Баталгаажуулахад алдаа гарлаа
            </Text>
            <Text color="gray">/алдааны мэдээлэл/</Text>
            <Button mt={50} variant="gradient" gradient={{ from: "blue", to: "purple" }} size="md" w="40%" component="a" href="/auth/login">
              Нэвтрэх хуудасруу буцах
            </Button>
          </Box>
        </div>
      )}
    </Layout>
  );
}

const useStyles = createStyles(() => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    maxWidth: 624,
    width: "100%",
    borderRadius: 10,
    gap: 10,
    position: "relative",
  },
}));
