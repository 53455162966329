import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { AuthApi, ProductsApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: any;
};

export function RequestPinForm({
  action,
  onCancel,
  title,
  subTitle,
  reload,
  type,
}: {
  action: any;
  onCancel: () => void;
  title: string;
  subTitle: string;
  reload: () => void;
  type: string;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data] = React.useState<IFormData>({
    pin: "",
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: values?.pin });

      if (action && action[0] === "CONTROL") {
        await ProductsApi.commission(action[1].id as string, {
          suppFee: action[1].suppFee,
          suppCalculateDay: action[1].suppCalculateDay,
          suppAppFee: action[1].suppAppFee,
          suppDisbursementFee: action[1].suppDisbursementFee,
          suppFeeRule: action[1].suppFeeRule,
          suppMinFee: action[1].suppMinFee,
          suppPenaltyType: action[1].suppPenaltyType,
          suppPenaltyPercent: action[1].suppPenaltyPercent,
          buyerFee: action[1].buyerFee,
          buyerCalculateDay: action[1].buyerCalculateDay,
          buyerAppFee: action[1].buyerAppFee,
          buyerDisbursementFee: action[1].buyerDisbursementFee,
          buyerFeeRule: action[1].buyerFeeRule,
          buyerPenaltyType: action[1].buyerPenaltyType,
          buyerPenaltyPercent: action[1].buyerPenaltyPercent,
          buyerMinFee: action[1].buyerMinFee,
          toReview: true,
        });

        if (type === "SUPPLIER_LED") {
          navigate("/scf-dcf-product/scf-product");
        } else {
          navigate("/scf-dcf-product/dcf-product");
        }
      } else if (action && action[0] === "ISCOMPOLETE") {
        await ProductsApi.toreview(action && action[1].id);
      }

      reload();
      onCancel && onCancel();
      Message.success("Бүтээгдэхүүнийг амжилттай хяналтад илгээсэн.");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {() => {
          return (
            <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={12}>
                  <Alert
                    styles={{ message: { color: "#44566c", fontWeight: 500, textAlign: "justify" } }}
                    radius="md"
                    color="indigo"
                    withCloseButton={false}
                    title="Таны анхааралд">
                    Та {action[1]?.refCode} дугаартай Supply Chain Finance бүтээгдэхүүн бүртгэлийг дуусгах гэж байна. Бүтээгдэхүүнийг хянахад таны и-мэйл
                    хаяганд мэдэгдэл ирэх болно. Хяналтанд илгээхдээ итгэлтэй байвал ПИН кодоо оруулаад “Баталгаажуулах” сонголт хийнэ үү.
                  </Alert>
                </Grid.Col>
                <Grid.Col span={12}>
                  <OtpField name="pin" label="ПИН код" placeholder="" required />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text component="p" fz="sm" fw={500}>
                    Танд амжилт хүсье!
                  </Text>
                </Grid.Col>
              </Grid>
              <Divider my="lg" />
              <Flex mt={20} direction="row" justify="space-between">
                <Button variant="default" onClick={() => onCancel && onCancel()}>
                  Болих
                </Button>
                <Flex gap="md">
                  <Button variant="outline" type="submit">
                    Баталгаажуулах
                  </Button>
                </Flex>
              </Flex>
            </FormLayout>
          );
        }}
      </Form>
    </>
  );
}
