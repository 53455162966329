import { Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { LbfProductApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { ISystemHistory } from "../../interfaces/ISystemHistory";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

export function ProductSupplierLedHistoryList({ id }: { id: string }) {
  const { programHistoryTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const columns = useHeader({ programHistoryTypes });

  return (
    <div style={{ marginTop: 10 }}>
      <Table
        name="supplier-led-product-credit.list"
        columns={columns}
        filters={{ actionUserId: "", type: "", lbfProductId: id }}
        loadData={(data) => LbfProductApi.history(data!)}
      />
    </div>
  );
}

const useHeader = ({ programHistoryTypes }: { programHistoryTypes: IReference[] }): ColumnType<ISystemHistory>[] => [
  {
    title: "#",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
    width: "30px",
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "productRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.productRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Системд хийсэн үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {(programHistoryTypes || []).find((c: IReference) => c?.code === record?.type)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (_record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {"-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгч",
    sorter: true,
    dataIndex: "actionUser",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.actionUser?.lastName[0] || "-"}.{record?.actionUser?.firstName || "-"}
        </Text>
      );
    },
  },
];
