import { Card, SimpleGrid, Text, UnstyledButton, clsx, createStyles } from "@mantine/core";
import { NavLink } from "react-router-dom";
import { navigationData } from "../constants/navigation";

const useStyles = createStyles((theme) => {
  return {
    card: {
      border: 0,
    },
    title: {
      fontWeight: 700,
    },

    item: {
      minHeight: 95,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: 7,
      border: `1px solid #ffff0000`,
      width: 130,
      padding: theme.spacing.xs,
      color: theme.white,
      fontWeight: 500,
      backgroundColor: "#ffffff09",
      transition: "box-shadow 150ms ease, transform 100ms ease",
      position: "relative",
      "&:hover": {
        boxShadow: `${theme.shadows.md} !important`,
        transform: "scale(1.05)",
      },
    },
    active: {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#ffff",
      background: "#ffffff19",
    },
    icon: {
      color: "#ffff",
    },
  };
});

export function ActionsGrid() {
  const { classes } = useStyles();

  const items = (navigationData || []).map((item) => (
    <NavLink to={item.link} key={item.link}>
      {({ isActive }) => (
        <UnstyledButton key={item.title} className={clsx(classes.item, { [classes.active]: isActive })}>
          <div className={classes.icon}>
            <item.icon />
          </div>
          <Text size="xs" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      )}
    </NavLink>
  ));

  return (
    <Card withBorder bg={"transparent"} className={classes.card} mih={200} onClick={() => {}}>
      <SimpleGrid cols={3}>{items}</SimpleGrid>
    </Card>
  );
}
