import { Avatar, Flex, Group, Space, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { OverdueApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReminder } from "../../interfaces/IReminder";
import { IReference } from "../../models/General";
import { ColumnType, Table } from "../table";

type Props = {
  action: any;
};

export function CollectionOverdueMailForm({ action }: Props) {
  const { reminderConnectTypes, reminderTypes, reminderLevels, reminderResponses, reminderResults } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [filters] = React.useState({
    repaymentId: action && action[1]?.id,
    isEmail: `${true}`,
  });

  const columns = useHeader({
    reminderConnectTypes,
    reminderTypes,
    reminderLevels,
    reminderResponses,
    reminderResults,
    onClick: (key, item) => {},
  });

  return (
    <>
      <Space mt="md" />
      <Table name="collection-overdue-mail.list" columns={columns} filters={filters} loadData={(data) => OverdueApi.reminderList(data!)} />
    </>
  );
}

const useHeader = ({
  onClick,
  reminderConnectTypes,
  reminderTypes,
  reminderLevels,
  reminderResponses,
  reminderResults,
}: {
  onClick: (key: string, record: IReminder) => void;
  reminderConnectTypes: any;
  reminderTypes: any;
  reminderLevels: any;
  reminderResponses: any;
  reminderResults: any;
}): ColumnType<IReminder>[] => [
  {
    title: "#",
    width: "50px",
    render: (_r, index) => `${index + 1}`,
  },
  {
    title: "Холбогдсон төрөл",
    dataIndex: "connectType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderConnectTypes.find((item2: IReference) => item2.code === record.connectType)?.name}
        </Text>
      );
    },
  },
  {
    title: "Сануулгын төрөл",
    dataIndex: "reminderType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderTypes.find((item2: IReference) => item2?.code === record?.reminderType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сануулга зэрэглэл",
    dataIndex: "reminderLevel",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderLevels.find((item2: IReference) => item2?.code === record?.reminderLevel)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Амлалтын хариу",
    dataIndex: "response",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderResponses.find((item2: IReference) => item2?.code === record?.response)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчид очих тэмдэглэл",
    dataIndex: "clientText",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {record.clientText || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дотоод тэмдэглэл",
    dataIndex: "internalText",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {record.internalText || "-"}
        </Text>
      );
    },
  },
  {
    title: "Холбогдсон үр дүн",
    dataIndex: "result",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {reminderResults.find((item2: IReference) => item2?.code === record?.result)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Холбоо барьсан",
    dataIndex: "remindedUser",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.remindedUser?.avatar !== null && record?.remindedUser !== null ? record?.remindedUser?.avatar : noimage} />
          <div style={{ width: 150 }}>
            <Text size="sm" weight={500} c="#44566C">
              {record?.remindedUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" weight={500} c="#141517">
                {record?.remindedUser?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
];
