import { Avatar, Badge, Flex, Group, Space, Text } from "@mantine/core";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { CompletedRequestApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IFundingRequest } from "../../interfaces/IFundingRequest";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, ITableRef, Table } from "../table";

export const ScfFundingProvidedSupplierList = () => {
  const ref = useRef<ITableRef>(null);
  const { currencies, requestStatus, fundingInstructionStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    currencies,
    requestStatus,
    fundingInstructionStatus,
    tugrug,
  });

  return (
    <>
      <Space h={20} />
      <Table
        ref={ref}
        name="scf.funding.funding.provided.list"
        columns={columns}
        filters={{ query: "", productType: "SUPPLIER_LED", type: "SUPPLIER" }}
        loadData={(data) => CompletedRequestApi.list(data!)}
      />
    </>
  );
};

const useHeader = ({
  requestStatus,
  fundingInstructionStatus,
  tugrug,
  currencies,
}: {
  requestStatus: IReference[];
  fundingInstructionStatus: IReference[];
  tugrug: any;
  currencies: any;
}): ColumnType<IFundingRequest>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "Хүсэлтийн #",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {dateSecFormat(record.createdAt)}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.requestedBusiness?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.requestedBusiness?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Нийлүүлэгч",
    sorter: true,
    dataIndex: "requestedBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.requestedBusiness?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.requestedBusiness?.partner?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хөтөлбөр нэр",
    sorter: true,
    dataIndex: "program",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.program?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.program?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Бүтээгдэхүүн",
    sorter: true,
    dataIndex: "product",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.product?.name || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.product?.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "requestedAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.requestedAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((item: IReference) => item.code === record?.currency)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хүсэлт төлөв",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color} 1px solid`,
            color: `${(requestStatus || []).find((c: any) => c?.code === record.requestStatus)?.color}`,
          }}>
          {(requestStatus || []).find((c: IReference) => c.code === record.requestStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Хоног",
    sorter: true,
    dataIndex: "financePeriod",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.financePeriod || "0"}
        </Text>
      );
    },
  },
  {
    title: "Санхүүжилт шимтгэл",
    sorter: true,
    dataIndex: "calculatedFeeAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.calculatedFeeAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Банк шимтгэл",
    sorter: true,
    dataIndex: "bankFeeAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.bankFeeAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх",
    sorter: true,
    dataIndex: "invRefCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.invRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх үлдэгдэл",
    sorter: true,
    dataIndex: "invAmountToPay",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.invAmountToPay || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Нэхэмжлэх огноо",
    sorter: true,
    dataIndex: "invCreatedAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.invCreatedAt)}
        </Text>
      );
    },
  },
  {
    title: "Баталсан огноо",
    sorter: true,
    dataIndex: "invConfirmedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.invConfirmedDate)}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх баталсан",
    sorter: true,
    dataIndex: "invConfirmedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.invConfirmedUser?.avatar !== null ? record?.invConfirmedUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.invConfirmedUser?.lastName[0] || "-"}.{record?.invConfirmedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Худ.авагч бизнес",
    sorter: true,
    dataIndex: "invReceiverBusiness",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.profileName : record?.requestedBusiness?.profileName}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.refCode : record?.requestedBusiness?.refCode}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Худалдан авагч",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.businessName : record?.requestedBusiness?.partner?.businessName}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record.type === "SUPPLIER" ? record?.invReceiverBusiness?.partner?.refCode : record?.requestedBusiness?.partner?.refCode}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн",
    sorter: true,
    dataIndex: "requestedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.requestedUser?.avatar !== null ? record?.requestedUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.requestedUser?.lastName[0] || "-"}.{record?.requestedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Олголтын төлөв",
    sorter: true,
    dataIndex: "fundingInstructionStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.color}>
          {(fundingInstructionStatus || []).find((item: IReference) => item.code === "DISBURSED")?.name}
        </Badge>
      );
    },
  },
  {
    title: "Банк ажилтан",
    sorter: true,
    dataIndex: "respondedUser",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.respondedUser?.avatar !== null ? record?.respondedUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.respondedUser?.lastName[0] || "-"}.{record?.respondedUser?.firstName}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Олгосон дүн",
    sorter: true,
    dataIndex: "approvedAmount",
    width: 150,
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.approvedAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Шийдвэр огноо",
    sorter: true,
    dataIndex: "respondedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record?.respondedDate)}
        </Text>
      );
    },
  },
  {
    title: "Олгосон огноо",
    sorter: true,
    dataIndex: "disbursedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record?.disbursedDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх дүн",
    sorter: true,
    dataIndex: "repaymentAmount",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.repaymentAmount || "0")}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Эргэн төлөх огноо",
    sorter: true,
    dataIndex: "repaymentDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record?.repaymentInv?.repaymentDate)}
        </Text>
      );
    },
  },
  {
    title: "Эргэн төлөх нэхэмжлэх",
    sorter: true,
    dataIndex: "repaymentInv",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.repaymentInv?.refCode || "-"}
        </Text>
      );
    },
  },
];
