import { ActionIcon, Avatar, Badge, Button, Checkbox, Divider, Drawer, Flex, Modal, Paper, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { IconCircleCheck, IconCircleMinus, IconEdit, IconInfoSquare, IconMailForward, IconX } from "@tabler/icons";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { UserApi } from "../../../apis";
import noimage from "../../../assets/no-image.png";
import { PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { UserDetail } from "../../../components/user-control/detail";
import { UserControlForm } from "../../../components/user-control/form";
import { IAuth } from "../../../interfaces/IAuth";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IPermissions } from "../../../interfaces/IPermissions";
import { IUserControl } from "../../../interfaces/IUserControl";
import { IReference } from "../../../models/General";
import { Dialog } from "../../../utils/confirm-modal";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";

export function UserList() {
  const breadcrumbs = useBreadcrumb();
  const theme = useMantineTheme();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { userStatus, userRegisterStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const ref = React.useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<IUserControl | null>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/user") && !location.pathname.includes("/user/user-list")) {
      navigate("/user/user-list", { replace: true });
    }
  }, [location, navigate]);

  const reloadTable = () => {
    ref.current?.reload();
  };

  const columns = useHeader({
    user,
    userStatus,
    userRegisterStatus,
    loading,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setPayload(item);
          setAction(["update"]);
          break;
        }
        case "detail": {
          setAction(["detail", item as any]);
          break;
        }
        case "active": {
          Dialog.confirm("Та хэрэглэгчийг идэвхтэй болгох гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await UserApi.active(item.id);
                  Message.success("Таны үйлдэл амжилттай.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reloadTable();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "inactive": {
          Dialog.confirm("Та хэрэглэгчийг идэвхгүй болгох гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await UserApi.in_active(item.id);
                  Message.success("Таны үйлдэл амжилттай.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reloadTable();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "register": {
          Dialog.confirm("Та баталгаажуулах линкийг дахин илгээх гэж байна. Итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await UserApi.invite(item.id);
                  Message.success("Хэрэглэгчид урилгын линк амжилттай илгээсэн.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  return (
    <>
      <PageLayout
        title="Системийн хэрэглэгч"
        subTitle="Систеийн хэрэглэгчийн жагсаалт"
        breadcrumb={breadcrumbs}
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "USER" && c.code === "USER_MANAGEMENT" && c.isCreate).length > 0 && (
              <Button
                key={2}
                onClick={() => {
                  setPayload(null);
                  setAction(["new"]);
                }}>
                Хэрэглэгч нэмэх
              </Button>
            )}
          </div>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Table ref={ref} name="user.control.list" columns={columns} filters={{ query: "" }} loadData={(data) => UserApi.list(data!)} />
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "update" || action[0] === "new"} onClose={() => setAction([])} withCloseButton={false} size="80%" centered>
        <PageLayout
          title={action[0] === "update" ? "Хэрэглэгчийн мэдээлэл засах" : "Системийн хэрэглэгч бүртгэх"}
          extra={[
            <ActionIcon onClick={() => setAction([])}>
              <IconX />
            </ActionIcon>,
          ]}>
          <UserControlForm onCancel={onCancel} action={action} payload={payload || undefined} reloadTable={reloadTable} />
        </PageLayout>
      </Modal>
      <Drawer
        position="right"
        opened={action[0] === "detail"}
        onClose={() => {
          onCancel();
        }}
        withCloseButton={false}
        overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
        overlayOpacity={0.55}
        overlayBlur={3}
        styles={{ drawer: { overflowY: "scroll" } }}
        size="80%">
        <PageLayout
          extra={[
            <ActionIcon onClick={() => setAction([])} key={1}>
              <IconX />
            </ActionIcon>,
          ]}
          title="Хэрэглэгчийн мэдээлэл">
          <Flex direction="column" gap={20}>
            <Divider />
            <UserDetail onCancel={onCancel} action={action} />
          </Flex>
        </PageLayout>
      </Drawer>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  userStatus,
  userRegisterStatus,
  loading,
}: {
  user: any;
  onClick: (key: string, record: IUserControl) => void;
  userStatus: IReference[];
  userRegisterStatus: IReference[];
  loading: boolean;
}): ColumnType<IUserControl>[] => [
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "USER" && c.code === "USER_MANAGEMENT" && c.isEdit).length > 0 &&
            !record.isPhoneVerified &&
            !record.isEmailVerified &&
            !record.hasPassword && (
              <Tooltip label="Засварлах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                  <IconEdit />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "USER" && c.code === "USER_INVITE").length > 0 &&
            record.registerStatus !== "SUCCESS" && (
              <Tooltip label="Илгээх" position="bottom">
                <Button loading={loading} variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("register", record)}>
                  <IconMailForward />
                </Button>
              </Tooltip>
            )}
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "USER" && c.code === "USER_STATUS_CHANGE").length > 0 &&
            record?.registerStatus !== "DRAFT" &&
            record.userStatus === "INACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button loading={loading} variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "USER" && c.code === "USER_STATUS_CHANGE").length > 0 &&
            record?.registerStatus !== "DRAFT" &&
            record.userStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("inactive", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return <Avatar src={record.avatar ? record.avatar : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "И-мэйл хаяг",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгж",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.departmentUnit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Албан тушаал",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.employeeUnit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "userStatus",
    render: (record) => {
      return (
        <>
          {record.userStatus !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                color: `${userStatus.find((item: IReference) => item.code === record.userStatus)?.color}`,
                border: `1px solid ${userStatus.find((item: IReference) => item.code === record.userStatus)?.color}`,
                background: "white",
              }}>
              {(userStatus || []).find((item: IReference) => item.code === record.userStatus)?.name}
            </Badge>
          ) : (
            <Text size="sm" weight={500} c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "registerStatus",
    render: (record) => {
      return (
        <>
          {record.registerStatus !== null ? (
            <Badge
              size="md"
              radius="sm"
              variant="outline"
              style={{
                color: `${userRegisterStatus.find((item: IReference) => item.code === record.registerStatus)?.color}`,
                border: `1px solid ${userRegisterStatus.find((item: IReference) => item.code === record.registerStatus)?.color}`,
                background: "white",
              }}>
              {(userRegisterStatus || []).find((item: IReference) => item.code === record.registerStatus)?.name}
            </Badge>
          ) : (
            <Text size="sm" weight={500} c="#44566C">
              {"-"}
            </Text>
          )}
        </>
      );
    },
  },
  {
    title: "Утас баталгаажуулалт",
    sorter: true,
    dataIndex: "isPhoneVerified",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isPhoneVerified}
          label={
            record.isPhoneVerified ? (
              <Text size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "И-мэйл баталгаажуулалт",
    sorter: true,
    dataIndex: "isEmailVerified",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isEmailVerified}
          label={
            record.isEmailVerified ? (
              <Text size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Нууц үг",
    sorter: true,
    dataIndex: "hasPassword",
    render: (record) => {
      return (
        <Checkbox
          checked={record.hasPassword}
          label={
            record.hasPassword ? (
              <Text size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  // {
  //   title: "Дан систем баталгаажуулалт",
  //   sorter: true,
  //   dataIndex: "sku",
  //   render: (record) => {
  //     return (
  //       <Checkbox
  //         checked={record.isDanVerified}
  //         label={
  //           record.isDanVerified ? (
  //             <Text size="sm" weight={500} c="#44566C">
  //               {"Тийм"}
  //             </Text>
  //           ) : (
  //             <Text size="sm" weight={500} c="#44566C">
  //               {"Үгүй"}
  //             </Text>
  //           )
  //         }
  //         onChange={() => {}}
  //       />
  //     );
  //   },
  // },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгч удирдлага",
  },
  {
    label: "Системийн хэрэглэгчид",
  },
];
