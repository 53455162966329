import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { AuthApi, LbfProductApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
};

export function ProductBuyerLedPinForm({
  onCancel,
  title,
  subTitle,
  action,
  reload,
  type,
}: {
  onCancel: () => void;
  title: string;
  subTitle: string;
  action: any;
  reload: () => void;
  type: string;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data] = React.useState<IFormData>({
    pin: "",
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: value.pin });

      switch (action && action[0]) {
        case "update": {
          await LbfProductApi.both(action[1].id, {
            borrowerType: action[1].borrowerType,
            suppInterestPercent: action[1].suppInterestPercent,
            suppInterestType: action[1].suppInterestType,
            suppInterestMinAmount: action[1].suppInterestMinAmount,
            suppDisbursementFee: action[1].suppDisbursementFee,
            suppAppFee: action[1].suppAppFee,
            recourseTerm: action[1].recourseTerm,
            recourseDays: action[1].recourseDays,
            suppTerminatePeriod: action[1].suppTerminatePeriod,
            toReview: true,

            buyerInterestPercent: action[1].buyerInterestPercent,
            buyerInterestType: action[1].buyerInterestType,
            buyerInterestMinAmount: action[1].buyerInterestMinAmount,
            buyerDisbursementFee: action[1].buyerDisbursementFee,
            buyerAppFee: action[1].buyerAppFee,
            buyerTermDays: action[1].buyerTermDays,
          });
          break;
        }
        case "is_compolete": {
          await LbfProductApi.toreview(action && action[1].id);
          break;
        }
      }

      reload();
      onCancel && onCancel();
      Message.success("Бүтээгдэхүүнийг амжилттай хяналтад илгээсэн.");
      navigate(`/cf/product/${type === "Supplier-LED" ? "SUPPLIER" : "BUYER"}`);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Divider />
            <Grid>
              <Grid.Col span={12}>
                <Alert
                  mt="xs"
                  styles={{ message: { color: "#44566c", fontWeight: 500, textAlign: "justify" } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Таны анхааралд">
                  Та зээл суурьтай {type} санхүүжилтийн бүтээгдэхүүн амжилттай бүртгэлээ. Хяналтад илгээхийн тулд ПИН кодоо оруулж баталгаажуулна уу.
                </Alert>
              </Grid.Col>
              <Grid.Col span={12}>
                <OtpField name="pin" label="ПИН код" placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
            </Grid>
            <Divider my="lg" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit">
                  Баталгаажуулах
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
