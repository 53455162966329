import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { AuthApi, ProductsApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: string;
  reviewDesc: string;
  review: boolean;
};

export function ControlForm({
  title,
  onCancel,
  action,
  subTitle,
  reload,
  setOpened,
}: {
  title: string;
  subTitle: string;
  onCancel: () => void;
  action: any[];
  reload?: any;
  setOpened?: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    pin: "",
    reviewDesc: "",
    review: false,
  });

  const onSubmit = async (data: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: data.pin });
      await ProductsApi.review(action && action[1].id, { review: data.review, reviewDesc: data.reviewDesc });

      if (data.review) {
        Message.success("Та бүтээгдэхүүнийг амжилттай хянасан.");
      } else {
        Message.success("Та бүтээгдэхүүнийг амжилттай татгалзсан.");
      }
      setLoading(false);
      reload();
      setOpened([]);
      onCancel();
    } catch (err) {
      setLoading(false);
      Message.error((err as HttpHandler)?.message!);
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ setFieldValue }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setOpened([])} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Alert
                  styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                  radius="md"
                  color="indigo"
                  withCloseButton={false}
                  title="Бүтээгдэхүүний мэдээлэл">
                  {`Та ${action && action[1]?.refCode} кодтой SCF бүтээгдэхүүнийг хянах гэж байна.`}
                </Alert>
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField
                  name="reviewDesc"
                  label="Тайлбар, коммент бичих бол энд бичнэ үү"
                  placeholder="Тайлбар оруулах..."
                  maxLength={255}
                  withAsterisk={false}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <OtpField name="pin" label="Үйлдлийг баталгаажуулах бол ПИН кодоо оруулна уу." placeholder="" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => setOpened([])}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit" onClick={() => setFieldValue("review", false)} color="red">
                  Татгалзах
                </Button>
                <Button variant="filled" type="submit" onClick={() => setFieldValue("review", true)}>
                  Хянасан
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
