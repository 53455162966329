import { Badge, Box, Button, Checkbox, Divider, Flex, Grid, Input, Modal, Text } from "@mantine/core";
import { IconArrowLeft, IconSend } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { ControlForm } from "../buyer-product/control-form";
import { Form } from "../form";

const schema = yup.object({});

type IFormData = {
  name: number;
};

export function ScfProductDetailFinanceForm({
  action,
  onSuccess,
  onCancel,
  reload,
}: {
  action: any[];
  onSuccess: () => void;
  onCancel: () => void;
  reload: any;
}) {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productSuppFeeRules, borrowerTypes, productBuyerPenaltyTypes, productSuppPenaltyTypes, productBuyerFeeRules } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [data] = React.useState<IFormData>({
    name: "",
    ...(action && action[0] === "DETAIL" ? action[1] : {}),
  });
  const [opened, setOpened] = React.useState<string[]>([]);

  const onSubmit = (values: any) => {
    setOpened(["pin", values]);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="СИСТЕМИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Input.Wrapper label="Санхүүжилт авах тал: ">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {(borrowerTypes || []).find((item: IReference) => item?.code === action[1]?.borrowerType)?.blName}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Буцаан дуудах эсэх:">
                    <Box style={{ alignItems: "center" }}>
                      <Checkbox checked={action[1]?.recourseTerm ? true : false} label={action[1]?.recourseTerm ? "Тийм" : "Үгүй"} onChange={() => {}} />
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Буцаан дуудах хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.recourseDays || 0}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт зогсоох хугацаа:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppTerminatePeriod || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Худалдан авагчид өгөх хоног:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.buyerTerm || 0}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="НИЙЛҮҮЛЭГЧИЙН ТӨЛӨХ, ШИМТГЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилтийн хүү:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppFee || 0} %
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Хүү тооцох хоног:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppCalculateDay || 0}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(action[1]?.suppMinFee || 0)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Өргөдлийн хураамж:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {tugrug(action[1]?.suppAppFee || 0)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Олголтын шимтгэл:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppDisbursementFee || 0} %
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шимтгэл авах дүрэм:">
                    <Box style={{ alignItems: "center" }}>
                      <Badge size="md" radius="sm" variant="outline">
                        {(productSuppFeeRules || []).find((c: IReference) => c.code === action[1]?.suppFeeRule)?.name || "-"}
                      </Badge>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Хугацаа хэтэрсний алданги:">
                    <Box style={{ alignItems: "center" }}>
                      <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.suppPenaltyPercent || 0} %
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Алданги тооцох дүрэм:">
                    <Box style={{ alignItems: "center" }}>
                      <Badge size="md" radius="sm" variant="outline">
                        {(productSuppPenaltyTypes || [])?.find((c) => c.code === action[1]?.suppPenaltyType)?.name || "-"}
                      </Badge>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                {action[1]?.borrowerType !== "SUPPLIER_ONLY" && (
                  <>
                    <Grid.Col span={12}>
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} my="xs" label="ХУДАЛДАН АВАГЧИЙН ТӨЛӨХ ШИМТГЭЛ ТОХИРУУЛАХ" />
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Худалдан авагчийн шимтгэл:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerFee || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Шимтгэлийг тооцох хоног:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerCalculateDay || "-"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Өргөдлийн хураамж:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(action[1]?.buyerAppFee || "0")}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Миннимум шимтгэлийн дүн:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {tugrug(action[1]?.buyerMinFee || "0")}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Олголтын шимтгэл:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerDisbursementFee || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Шимтгэл авах дүрэм:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            <Badge size="md" radius="sm" variant="outline">
                              {(productBuyerFeeRules || [])?.find((c) => c.code === action[1]?.buyerFeeRule)?.name}
                            </Badge>
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={4}>
                      <Input.Wrapper label="Хугацаа хэтэрсний алданги:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            {action[1]?.buyerPenaltyPercent || "0"} {"%"}
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <Input.Wrapper label="Алданги тооцох дүрэм:">
                        <Box style={{ alignItems: "center" }}>
                          <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                            <Badge size="md" radius="sm" variant="outline">
                              {(productBuyerPenaltyTypes || [])?.find((c) => c.code === action[1]?.buyerPenaltyType)?.name}
                            </Badge>
                          </Text>
                        </Box>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={4}></Grid.Col>
                  </>
                )}
              </Grid>
              <Flex mt={20} direction="row" justify="space-between">
                <Button
                  variant="default"
                  leftIcon={<IconArrowLeft />}
                  onClick={() => {
                    onSuccess();
                  }}>
                  Өмнөх
                </Button>
                <Flex gap="md">
                  {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_SL" && c.isReview).length > 0 && (
                    <Button
                      rightIcon={<IconSend size={20} style={{ transform: "rotate(45deg)" }} />}
                      type="submit"
                      disabled={action && action[2] !== "CONTROL"}>
                      Хянах
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Modal
              withCloseButton={false}
              opened={opened && opened[0] === "pin"}
              onClose={() => {
                setOpened([]);
              }}>
              <ControlForm
                title="Баталгаажуулах пин код"
                subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
                onCancel={onCancel}
                action={opened}
                reload={reload}
                setOpened={setOpened}
              />
            </Modal>
          </>
        );
      }}
    </Form>
  );
}
