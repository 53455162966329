import { Table as BaseTable, Button, Center, Collapse, createStyles, Group, LoadingOverlay, Pagination, Select, Space, Text } from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconMinus, IconPlus, IconSelector } from "@tabler/icons";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  filters?: { [key: string]: string | number };
  pagination?: boolean;
  dataSource?: any;
  loadData?: (filter?: IFilter) => Promise<any>;
  collapse?: boolean;
  collapseChildren?: any;
  onResult?: any;
  height?: string;
  limit?: number;
};

export type ITableRef = { reload: () => void };

export const Table = React.forwardRef(
  (
    { name, columns, filters = {}, pagination = true, dataSource = [], loadData, collapse = false, collapseChildren, onResult, limit = 20 }: Props,
    ref: React.Ref<ITableRef>,
  ) => {
    const { classes } = useStyles();
    const [opened, setOpened] = React.useState<number[]>([]);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: limit });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );
    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
    };

    const onScroll = () => {};

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <BaseTable ref={tableRef} className={classes.table} horizontalSpacing="md" verticalSpacing="xs" highlightOnHover>
            <thead>
              <tr>
                {collapse && <th className={classes.collapseTh}></th>}
                {columns.map((column, index) => (
                  <Th key={index} column={column} sorted={sorted[0] === column.dataIndex && sorted[1]} onSort={() => onSort(column.dataIndex)}>
                    {column.title}
                  </Th>
                ))}
              </tr>
            </thead>

            {data?.rows?.length > 0 && onResult && onResult(data)}
            {data?.rows?.length > 0 ? (
              data?.rows?.map((row: any, index: number) => {
                return (
                  <tbody key={`${index}`}>
                    <tr>
                      {collapse && (
                        <td>
                          <Button
                            onClick={() => setOpened(opened.includes(index) ? opened.filter((f) => f !== index) : [...opened, index])}
                            size="xs"
                            w={25}
                            h={25}
                            p={0}
                            variant="default">
                            {opened.includes(index) ? <IconPlus size={18} stroke={1} /> : <IconMinus size={18} stroke={1} />}
                          </Button>
                        </td>
                      )}
                      {columns.map((column, index2) => {
                        return (
                          <td key={`${index2}`} className={classes.td} align={column.align || "left"}>
                            {column.render(row, index)}
                          </td>
                        );
                      })}
                    </tr>
                    {collapse && (
                      <tr key={`${index}_collapse`}>
                        <td colSpan={columns.length + 1} className={classes.tdDetail}>
                          <Collapse in={opened.includes(index)}>
                            <div className={classes.collapseContent}>{collapse && collapseChildren && collapseChildren(row)}</div>
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr key={"Noting"}>
                  <td colSpan={collapse ? columns.length + 1 : columns.length} className={classes.td}>
                    <Center sx={(theme) => ({ padding: theme.spacing.xl, flexDirection: "column", gap: 10 })}>
                      <IconDatabaseOff size={36} stroke={1.5} />
                      <Text weight={500} align="center">
                        Мэдээлэл олдсонгүй
                      </Text>
                    </Center>
                  </td>
                </tr>
              </tbody>
            )}
          </BaseTable>
          <Space h="md" />

          <LoadingOverlay visible={isLoading} />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              page={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={Math.ceil(data.count / offset.limit)}
              size="md"
              disabled={Math.ceil(data.count / offset.limit) === 1}
            />
            {data.count > 0 && (
              <Group ml={10} w={offset.limit === 100 ? 75 : 65}>
                <Select
                  onChange={(e: string) => {
                    setOffset((state) => ({ ...state, limit: parseInt(e), page: 1 }));
                  }}
                  value={`${offset.limit}`}
                  size="xs"
                  data={["10", "20", "50", "100"]}
                />
              </Group>
            )}
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
}

const Th = ({ children, column, sorted, onSort }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <th className={classes.th} style={{ minWidth: column.width }}>
        {children}
      </th>
    );

  return (
    <th className={`${classes.th} ${classes.control}`} style={{ width: column.width }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </th>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
  },
  scroll: {
    overflowX: "auto",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  table: {
    minWidth: 1000,
  },
  th: {
    padding: "8px!important",
    lineHeight: "14px",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "8px!important",
  },
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  collapseTh: {
    width: "24px",
  },
  collapseContent: {
    borderTop: "1px solid #dadada",
    padding: "14px 24px",
  },
  tdDetail: {
    padding: "0 !important",
    border: "none !important",
  },
}));
