import useSwr from "swr";
import { SectorApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { SelectField } from "../form/select-field";

export function SubSector({ sectorId, index, type, setFieldValue, values }: { sectorId: any; index: number; type: string; setFieldValue: any; values: any }) {
  const { data: sectorData } = useSwr(`/api/program/reference/economical_sector/get/${type}/${sectorId?.id}`, async () => {
    try {
      if (!sectorId.id) {
        return [];
      }
      let res = await SectorApi.get(`${sectorId?.id}`);
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const { data: subSectorData } = useSwr(`/program/reference/economical_sector/select/${type}/${sectorId?.id}`, async () => {
    try {
      let res = await SectorApi.select({ parentId: sectorId?.id, level: "3", query: "", limit: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <>
      {values?.programSector2Ids[index]?.id && (
        <SelectField
          name={`programSector3Ids[${index}].id`}
          label={`Дэд сектор / ${(sectorData || []).name}`}
          placeholder="сонгох"
          options={(subSectorData || [])
            .filter((c: any) => c?.parentId === sectorId?.id)
            .map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
          withAsterisk={false}
        />
      )}
    </>
  );
}
