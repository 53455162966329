import { ActionIcon, createStyles, Menu } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons";

type Props = {
  extra: { [key: string]: string | JSX.Element };
  onClick: (key: string) => void;
  hideKeys?: string[] | undefined[] | null[];
};

export function RowAction({ extra, onClick, hideKeys }: Props) {
  const { classes } = useStyles();

  return (
    <Menu transition="pop" position="bottom-end">
      <Menu.Target>
        <ActionIcon size={32} radius={100} className={classes.control}>
          <IconDotsVertical size={18} stroke={2} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {Object.keys(extra)
          .filter((key1) => !hideKeys?.some((item: string | undefined | null) => item === key1))
          .map((key, index) => {
            return (
              <Menu.Item key={index} onClick={() => onClick(key)}>
                {extra[key]}
              </Menu.Item>
            );
          })}
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles(() => ({
  control: {
    border: 0,
  },
}));
