import { Box, Group, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { IPayments } from "../../interfaces/IPayments";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

export function AccountSupplierHistory({ data }: { data: IPayments }) {
  const params = useParams();
  const navigate = useNavigate();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          navigate(`/cf/funding-request/${params.type || "SUPPLIER"}/request`);
          break;
        }
      }
    },
  });

  return (
    <div style={{ marginTop: 10 }}>
      <Table name="buyer-led-program-credit.list" columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<IPayments>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {index + 1}
        </Text>
      );
    },
  },
  {
    title: "Гүйлгээний огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {dateSecFormat(record?.createdAt)}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Харьцсан данс",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.payerAccName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.payerAccNum || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Төлсөн дүн",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.amount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Төлвөл зохих",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.firstAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Үлдэгдэл",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record?.lastAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.description || "-"}
        </Text>
      );
    },
  },
];
