import { Button, createStyles, Image, Input, Text } from "@mantine/core";
import Background from "../../assets/background-blur.svg";
import Layout from "../../components/home/layout";

export function VerifyPage() {
  const { classes } = useStyles();

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <div className={classes.container}>
        <div className={classes.card}>
          <div className={classes.icon}></div>
          <Text className={classes.title}>Баталгаажуулах код</Text>
          <Text className={classes.bodyText}>Таны бүртгэлтэй дугаар-руу явуулсан 6 оронтой тоог доорх талбарт оруулж мэдээлэлээ баталгаажуулна уу</Text>
          <Input className={classes.input} radius={8} size="lg" placeholder="Баталгаажуулах код" />
          <Button variant="gradient" radius={8} size={"lg"} className={classes.button} gradient={{ from: "#AD61FF", to: "#7165FA" }}>
            Баталгаажуулах
          </Button>
        </div>
        <div className={classes.bottomText}>© Funder, бүх эрх хуулиар хамгаалагдсан. {new Date().getFullYear()}</div>
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 500,
    maxWidth: 1024,
    borderRadius: 10,
    gap: 10,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    color: "black",
  },
  bodyText: {
    width: "80%",
    fontSize: 16,
    fontWeight: 400,
    paddingRight: 15,
    paddingLeft: 15,
    color: "black",
  },
  input: {
    marginTop: 25,
    width: "80%",
  },
  button: {
    marginTop: 15,
  },
  bottomText: {
    marginTop: 25,
    color: "white",
  },
  icon: {},
}));
