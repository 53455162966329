import { Badge, Box, Button, Divider, Flex, LoadingOverlay, Modal, Text, Tooltip } from "@mantine/core";
import { IconMailForward, IconSettings } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { RequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IStaffRole } from "../../interfaces/IStaffRole";
import { IReference } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ColumnType, ITableRef, Table } from "../table";
import { AncorDetailRoleForm } from "./role-form";

export function AncorDetailRoleList({ data, onSuccess }: { data: any; onSuccess?: () => void }) {
  const ref = React.useRef<ITableRef>(null);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = React.useState<string[]>([]);
  const { clientStaffLoginTypes, clientFinanceRoleStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);

  const columns = useHeader({
    data,
    user,
    clientStaffLoginTypes,
    clientFinanceRoleStatus,
    onClick: (key, item) => {
      switch (key) {
        case "success": {
          setAction(["success", item as any, data.id]);
          break;
        }
        case "invite": {
          Dialog.confirm("Та хэрэглэгчид урилга илгээх гэж байна. Та итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await RequestApi.userInvite(data.id as string, {
                    userId: item?.id,
                  });
                  Message.success("Бүртгэл баталгаажуулах и-мэйл илгээгдлээ.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                try {
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БАНКТАЙ ХАРИЛЦАХ АЖИЛТАН" mb="xs" />
      <Table ref={ref} name="ancor.detail.supplier.list" columns={columns} dataSource={data?.users?.rows} pagination={false} />
      <Modal withCloseButton={false} opened={action[0] === "success"} onClose={() => setAction([])} size={"1200px"} centered>
        <AncorDetailRoleForm reloadTab={onSuccess} action={action} onCancel={onCancel} />
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  data,
  user,
  clientStaffLoginTypes,
  clientFinanceRoleStatus,
}: {
  onClick: (key: string, record: IStaffRole) => void;
  data: any;
  user: any;
  clientStaffLoginTypes: IReference[];
  clientFinanceRoleStatus: IReference[];
}): ColumnType<IStaffRole>[] => [
  {
    title: "#",
    width: "30px",
    render: (_record, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {`${index + 1}`}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      const hasRespondPermission =
        (user.permissions || []).some(
          (c: IPermissions) => c.module === "CLIENT" && (c.code === "CLIENT_ANCHOR_USER_RESPOND" || c.code === "CLIENT_SME_USER_RESPOND"),
        ) && record?.financeRoleStatus === "ASSIGNED";

      const hasInvitePermission =
        (user.permissions || []).some(
          (c: IPermissions) => c.module === "CLIENT" && (c.code === "CLIENT_SME_USER_INVITE" || c.code === "CLIENT_ANCHOR_USER_INVITE"),
        ) &&
        record?.financeRoleStatus === "APPROVED" &&
        !record.isEmailVerified;

      return (
        <Flex gap="sm">
          <Tooltip label="Эрх зөвшөөрөх" position="bottom">
            <Button
              variant="light"
              radius={100}
              w={35}
              h={35}
              p={0}
              onClick={() => onClick(hasRespondPermission ? "success" : "", record)}
              disabled={!hasRespondPermission}>
              <IconSettings />
            </Button>
          </Tooltip>

          <Tooltip label="Урилга илгээх" position="bottom">
            <Button
              variant="light"
              radius={100}
              w={35}
              h={35}
              p={0}
              onClick={() => onClick(hasInvitePermission ? "invite" : "", record)}
              disabled={!hasInvitePermission}>
              <IconMailForward />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "profileName",
    render: () => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {data?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "finUser",
    render: (_record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {data?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d" w="max-content">
            {data?.refCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Регистрийн №",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.registerNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгчийн CIF",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.staffCif || "-"}
        </Text>
      );
    },
  },
  {
    title: "Ургийн овог",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.familyName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "partnerEmail",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эрх статус",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.color} 1px solid`,
            color: `${(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.color}`,
          }}>
          {(clientFinanceRoleStatus || []).find((c: IReference) => c?.code === record.financeRoleStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.dehubStatus || "-"}
        </Text>
      );
    },
  },
  {
    title: "Finance user",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.username || "-"}
        </Text>
      );
    },
  },
  {
    title: "Login type",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {clientStaffLoginTypes?.find((item: IReference) => item.code === record.staffLoginType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл баталгаажилт",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={`${record?.isEmailVerified}` === `${true}` ? "green" : "red"}>
          {record?.isEmailVerified ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Утас баталгаажилт",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={`${record?.isPhoneVerified}` === `${true}` ? "green" : "red"}>
          {record?.isPhoneVerified ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Нууц үг",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.password || "-"}
        </Text>
      );
    },
  },
];
