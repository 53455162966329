import { Avatar, Badge, Button, Divider, Drawer, Group, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { IconInfoSquare } from "@tabler/icons";
import React from "react";
import noimage from "../../assets/no-image.png";
import { IPartnerBuyer } from "../../interfaces/IPartnerBuyer";
import { ColumnType, Table } from "../table";
import { AncorDetailBuyerForm } from "./buyer-form";

export function AncorDetailBuyerList({ data }: { data: any }) {
  const [action, setAction] = React.useState<string[]>([]);

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["opened", record.id]);
          break;
        }
      }
    },
  });

  return (
    <>
      <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="БҮРТГЭЛТЭЙ BUYER БИЗНЕС" mb="xs" />
      <Table name="ancor.detail.buyers.list" columns={columns} dataSource={data || []} />
      <Drawer
        opened={action[0] === "opened"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        size="60%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <AncorDetailBuyerForm action={action} />
      </Drawer>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IPartnerBuyer) => void }): ColumnType<IPartnerBuyer>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Дэлгэрэнгүй" position="bottom">
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
            <IconInfoSquare />
          </Button>
        </Tooltip>
      );
    },
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.logo !== null ? record?.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Бизнесийн төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => (
      <Badge size="md" radius="sm" variant="outline">
        {record.type === "BUYER" ? "Худалдан авагч" : "Нийлүүлэгч"}
      </Badge>
    ),
  },
  {
    title: "Бизнес Ref#",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <UnstyledButton>
          <Group>
            <Badge size="md" radius="sm" variant="outline" onClick={() => {}}>
              {record.refCode || "-"}
            </Badge>
          </Group>
        </UnstyledButton>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "partnerEmail",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.partnerEmail || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "partnerPhone",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.partnerPhone || "-"}
        </Text>
      );
    },
  },
];
