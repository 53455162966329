import { Avatar, Checkbox, Flex, Text } from "@mantine/core";
import { ProgramApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { ColumnType, Table } from "../table";

export function BuyerLedProgramAnchorList({ filters, values, setFieldValue }: { filters: any; values: any; setFieldValue: any }) {
  const columns = useHeader({ values, setFieldValue });

  return <Table name="scf.sme.add.anchor.listg" columns={columns} filters={filters} loadData={(data) => ProgramApi.anchorList(data!)} />;
}

const useHeader = ({ values, setFieldValue }: any): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
  },
  {
    title: "Сонгох",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record?.id === values?.anchor?.id}
          onChange={(e) => {
            if (record?.id === values?.anchor?.id) {
              setFieldValue("businessId", undefined);
              setFieldValue("outAccId", undefined);
              setFieldValue("inAccId", undefined);
              setFieldValue("anchor", undefined);
              setFieldValue("bankAccounts", undefined);
              setFieldValue("checkedAnchor", undefined);
            } else {
              setFieldValue("businessId", e?.currentTarget?.value);
              setFieldValue("anchor", record);
              setFieldValue("bankAccounts", record?.bankAccounts);
              setFieldValue("outAccId", undefined);
              setFieldValue("inAccId", undefined);
              setFieldValue("checkedAnchor", `${record?.profileName || "-"}, #${record?.refCode || "-"}`);
            }
          }}
          value={record?.id}
        />
      );
    },
  },
  {
    title: "Партнер ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record.partnerName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#141517" w="max-content">
            {record.refCode || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Бизнес код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#141517" w="max-content">
          #{record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүү",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.finUser?.lastName[0] || "-"}.{record?.finUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Санхүү имэйл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.finUser?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Санхүү утас",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.finUser?.phone}
        </Text>
      );
    },
  },
];
