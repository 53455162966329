import { Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { Form } from "../form";

const schema = yup.object({});

export function AccountSupplierInformation({ data }: { data: any }) {
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);

  return (
    <Form validationSchema={schema} onSubmit={() => {}} initialValues={data}>
      {() => {
        return (
          <Flex sx={{ position: "relative" }} direction="column">
            <Grid mt={5}>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагчийн CIF: ">
                  <Text color="#317169" fz="sm" fw={600}>
                    {data?.lbfRequest.requestedClientCif || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="ТТД: ">
                  <Text color="#317169" fz="sm" fw={600}>
                    {data?.type === "BL" ? data?.buyerRegNumber : data?.supplierRegNumber}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Харилцагчийн нэр: ">
                  <Text color="#317169" fz="sm" fw={600}>
                    {data?.receiverAccName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Партнерийн нэр: ">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                    <Text color="#317169" fz="sm" fw={600} mr={5}>
                      {data?.business?.partner?.refCode || "-"}#,
                    </Text>
                    <Text size="sm" fw={600} c="#44566C">
                      {data?.business?.partner?.businessName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Данс хамаарах бизнес (зээл авсан): ">
                  <Flex justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                    <Text color="#317169" fz="sm" fw={600} mr={5}>
                      {data?.business?.refCode || "-"}#,
                    </Text>
                    <Text size="sm" fw={600} c="#44566C">
                      {data?.business?.profileName || "-"}
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Зээл хүссэн ажилтан: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.requestedUser?.lastName || "-"} {data?.requestedUser?.firstName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Хөтөлбөрийн нэр: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.lbfProgram?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний нэр: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.lbfProduct?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Нэхэмжлэх дугаар: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.invoiceRef || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Зээл олгосон дүн: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {tugrug(data?.amount || 0)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Олголт хүлээн авсан данс: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.receiverAccNum || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ДАНСНЫ МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Зээлийн дансны дугаар: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.accountNumber || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Зээлийн дансны нэр: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.accountName || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Бүтээгдэхүүний нэр: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.accountCategory || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Валют: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {(currencies || []).find((c: IReference) => c.code === data?.obiDetails?.currency)?.name || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Данс нээсэн огноо: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.openDate || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Данс хаагдах хугацаа: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.maturityDate || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа сараар: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.periodInMonths || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Дараа өр төлөх хугацаа: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {data?.obiDetails?.nextInstallmentDueDate || "-"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <Input.Wrapper label="Хугацаа хэтэрсэн дүн: ">
                  <Text color="#317169" fz="sm" fw={600} mr={5}>
                    {tugrug(data?.obiDetails?.overdueAmount || 0)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={4}>
                <Input.Wrapper label="Нийт үлдэгдэл: ">
                  <Text color="#317169" fz="sm" fw={500} mr={5}>
                    {tugrug(data?.obiDetails?.liabliltyAmount || 0)}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
          </Flex>
        );
      }}
    </Form>
  );
}
