import CurrencyFormat from "currency-formatter";
import { store } from "../store";
import { authChange } from "../store/Auth";
import { HttpHandler } from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

const percent = /^(100(\.0{0,2}?)?$|([1-9]|[1-9][0-9])(\.\d{1,2})?)$/gm;
const percentZero = /^(0*100{1,1}\.?((?<=\.)0*)?%?$)|(^0*\d{0,2}\.?((?<=\.)\d*)?%?)$/gm;

const currencyRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
const dayRegex = /^[0-9\b]{1,3}$/gm;
const registerNumber = /^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/;

const numberRegex = /[0-9]/gm;
const bankAccountRegex = /^\+?\d{10,15}$/;
const regNumber = /^[0-9]{7}$/;

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): HttpHandler => {
    if (statusCode === 401) {
      store.dispatch(authChange());
    }

    throw error;
  };
}

const tugrug = (value: number) =>
  CurrencyFormat.format(value, {
    code: "MNT",
    format: "%v %s",
    thousand: "'",
    decimal: ".",
  });

export { bankAccountRegex, currencyRegex, dayRegex, numberRegex, percent, percentZero, registerNumber, regNumber, tugrug };
