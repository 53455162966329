import { Badge, Box, Button, CloseButton, Divider, Flex, Grid, Group, Input, Modal, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSWR from "swr";
import * as yup from "yup";
import { AccountApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { BuyerProgramReviewForm } from "../buyer-led-program/review-form";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { ISupplierFormData } from "./main-form";

const schema = yup.object({});

export function SupplierProgramInformationForm({ action, basicSector, sector, subSector }: { action: any; basicSector: any; sector: any; subSector: any }) {
  const navigate = useNavigate();
  const [actions, setActions] = React.useState<string[]>([]);
  const { productTypes, programTypes, programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [data] = React.useState<ISupplierFormData>({
    type: "",
    productType: "",
    productId: "",
    name: "",
    resolutionNo: "",
    description: "",
    programLimit: "",
    financeStartDate: "",
    financePeriod: "",
    depositFee: "",
    depositFeeDays: "",
    programFee: "",
    disburseAccId: "",
    repayAccId: "",
    rechargeable: false,
    feeAccId: "",
    otherFeeAccId: "",
    programSector1Ids: [],
    programSector2Ids: [],
    programSector3Ids: [],
    toReview: false,

    businessId: "",
    finUserId: "",
    inAccId: "",

    ...(action && action[0] === "detail"
      ? {
          ...action[1],
          programSector1Ids: (action && action[1]?.programSectors?.map((item: any) => item?.economicalSectorId)) || [],
          inAccId: action[1] && action[1]?.anchor?.inAccId,
          businessId: action[1] && action[1]?.anchor?.businessId,
          ancorpartner: action[1] && action[1]?.anchor?.partner,
          finUserId: action[1] && action[1]?.anchor?.finUserId,
        }
      : {}),
  });

  const { data: accountData } = useSWR(
    `/api/supplier/program/account/get/${action && action[1].repayAccId}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_REPAYMENT" && c.isView).length > 0) {
        let res = await AccountApi.get(`${action && action[1].repayAccId}`);
        return res;
      }
      return [];
    },
    {},
  );

  const { data: disburseAccData } = useSWR(
    `/api/supplier/program/account/get/${action && action[1].disburseAccId}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_DISBURSEMENT" && c.isView).length > 0) {
        let res = await AccountApi.get(`${action && action[1].disburseAccId}`);
        return res;
      }
      return [];
    },
    {},
  );

  const { data: feeAccData } = useSWR(
    `/api/supplier/program/account/get/${action && action[1].feeAccId}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_ACCOUNT_FEE" && c.isView).length > 0) {
        let res = await AccountApi.get(`${action && action[1].feeAccId}`);
        return res;
      }
      return [];
    },
    {},
  );

  const reload = () => {};
  const onSubmit = () => {};

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <>
              <Grid gutter={24} mt={20}>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Хөтөлбөрийн код:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {values?.refCode || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Хөтөлбөрийн төрөл:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {programTypes.find((item) => item.code === values?.type)?.name || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Бүтээгдэхүүний нэр:">
                    <Flex align="center" gap={8}>
                      <Text fw={500} color="indigo" fz={"sm"}>
                        {values?.product?.name}, #{values?.product?.refCode}
                      </Text>
                      <Text fz="sm" fw={500} color="dimmed" component="div">
                        ({productTypes.find((item) => item.code === values?.productType)?.name || "-"})
                      </Text>
                    </Flex>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Бүртгэсэн:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      <Flex gap={5} wrap="wrap">
                        <span>{values?.regUser?.lastName || user?.lastName || "-"}</span>
                        <span>{values?.regUser?.firstName || user?.firstName || "-"}</span>
                        <Text color="dimmed" component="div">
                          ({values?.createdAt ? dateSecFormat(values.createdAt) : dateSecFormat(new Date())})
                        </Text>
                      </Flex>
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Бүртгэлийн статус:">
                    <Box style={{ alignItems: "center" }}>
                      <Badge
                        size="md"
                        radius="sm"
                        variant="outline"
                        color={(programRegistrationStatus || []).find((item: IReference) => item.code === values?.registrationStatus)?.color}>
                        {(programRegistrationStatus || []).find((item: IReference) => item.code === values?.registrationStatus)?.name}
                      </Badge>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Хөтөлбөрийн статус:">
                    <Box style={{ alignItems: "center" }}>
                      {
                        <Badge
                          size="md"
                          radius="sm"
                          variant="outline"
                          color={(programStatus || []).find((item: IReference) => item.code === values?.programStatus)?.color}>
                          {(programStatus || []).find((item: IReference) => item.code === values?.programStatus)?.name}
                        </Badge>
                      }
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={6} lg={12}>
                  <Divider />
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Хөтөлбөрийн нэр:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      <Flex gap={5} wrap="wrap">
                        <span>{values?.name || "-"}</span>
                      </Flex>
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Хорооны шийдвэрийн дугаар:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      <Flex gap={5} wrap="wrap">
                        <span>{values?.resolutionNo || "-"}</span>
                      </Flex>
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}></Grid.Col>

                <Grid.Col span={12}>
                  <Input.Wrapper label="Тайлбар :">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {values?.description || "-"}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  {(basicSector || []).length !== 0 && (
                    <Input.Wrapper label="Үндсэн сектор:">
                      <Group mt={"xs"}>
                        {(basicSector || []).map((item: any, index: number) => {
                          return (
                            <Badge key={index} radius="sm" variant="outline">
                              {item?.economicalSector?.name}
                            </Badge>
                          );
                        })}
                      </Group>
                    </Input.Wrapper>
                  )}
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  {(sector || []).length !== 0 && (
                    <Input.Wrapper label="Сектор:">
                      <Group mt={"xs"}>
                        {(sector || []).map((item: any, index: number) => {
                          return (
                            <Badge key={index} radius="sm" variant="outline">
                              {item?.economicalSector?.name}
                            </Badge>
                          );
                        })}
                      </Group>
                    </Input.Wrapper>
                  )}
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  {(subSector || []).length !== 0 && (
                    <Input.Wrapper label="Дэд сектор:">
                      <Group mt={"xs"}>
                        {(subSector || []).map((item: any, index: number) => {
                          return (
                            <Badge key={index} radius="sm" variant="outline">
                              {item?.economicalSector?.name}
                            </Badge>
                          );
                        })}
                      </Group>
                    </Input.Wrapper>
                  )}
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider />
                </Grid.Col>

                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Банкны олголтын данс:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {disburseAccData?.bankName} {disburseAccData?.number}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="SCF шимтгэл төвлөрүүлэх данс:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {feeAccData?.bankName} {feeAccData?.number}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={6} lg={4}>
                  <Input.Wrapper label="Эргэн төлөлт төвлөрүүлэх данс:">
                    <Text fw={500} color="indigo" fz={"sm"}>
                      {accountData?.bankName} {accountData?.number}
                    </Text>
                  </Input.Wrapper>
                </Grid.Col>

                {values.hasAnchor && (
                  <>
                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        <Text color="indigo">
                          {values?.anchor?.partnerName || values?.anchor?.partner?.businessName || "-"}, #{values?.anchor?.refCode || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Хөтөлбөрт хамрагдах нийлүүлэгч нэр:">
                        <Text color="indigo">
                          {values?.anchor?.profileName || "-"}, #{values?.anchor?.refCode || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Хөтөлбөр дэх анкорын роль:">
                        <Text color="indigo">{values?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Хөтөлбөрийн лимит:">
                        <Text color="indigo">{tugrug(values?.programLimit || 0)}</Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Нийлүүлэгчийн лимит:">
                        <Text color="indigo">{tugrug(values?.programLimit || 0)}</Text>
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Нийлүүлэгчийн лимит:">
                        <Text color="indigo">{values?.rechargeable ? "Эргэн төлөлтөөс боломжит лимит өснө." : "Хөтөлбөрийн лимит 0 болоход зогсоно"}</Text>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col md={6} lg={4}>
                      <Input.Wrapper label="Нэхэмжлэл батлах санхүү:">
                        <Text color="indigo">
                          {values?.anchor?.finUser?.lastName || "-"} {values?.anchor?.finUser?.lastName || "-"}
                        </Text>
                      </Input.Wrapper>
                    </Grid.Col>
                  </>
                )}
              </Grid>
              <Divider h="lg" mt="lg" />
              <Flex direction="row" justify="space-between">
                <Button
                  onClick={() => {
                    navigate("/scf-dcf/scf-program");
                  }}
                  variant="default">
                  Болих
                </Button>
                <Flex gap="md">
                  <>
                    {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_SL" && c.isReview).length > 0 &&
                      values?.registrationStatus &&
                      values?.isSent && <Button onClick={() => setActions(["review", values as any])}>Хянах</Button>}
                  </>
                </Flex>
              </Flex>
            </>
          );
        }}
      </Form>
      <Modal opened={actions[0] === "review"} onClose={() => setActions([])} withCloseButton={false} size="lg" centered>
        {actions[0] === "review" && (
          <FormLayout
            title="Үндсэн мэдээлэл хянах"
            subTitle="Supply Chain Finance хөтөлбөрийн үндсэн бүртгэл хянах"
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => setActions([])} />]}>
            <BuyerProgramReviewForm data={action && action[1]} reload={reload} onCancel={() => setActions([])} type="suppler_led" />
          </FormLayout>
        )}
      </Modal>
    </>
  );
}
