import { Button, CloseButton, Divider, Flex } from "@mantine/core";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import * as yup from "yup";
import { ProductsApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { SelectField } from "../form/select-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  productId: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  productId: string;
};

type ISelect = {
  name: string;
  refCode: string;
};

export function BuyerProgramRegisterForm({ onCancel }: { onCancel: () => void }) {
  const navigate = useNavigate();
  const formRef =
    useRef<IFormRef<{ submit: () => any; validate: () => Promise<{ [key: string]: string }>; setFormData: (callback: (state: any) => any) => void } | any>>(
      null,
    );
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    productId: "",
  });
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { data: productData } = useSwr(`/buyer-led-program/product/list`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0) {
      try {
        let res = await ProductsApi.select({ query: "", type: "BUYER_LED" });
        return res;
      } catch (error) {
        Message.error((error as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    navigate(`/scf-dcf/dcf-program/${data.productId}/new`);
    setLoading(false);
  };

  return (
    <FormLayout
      title="Buyer LED хөтөлбөр бүртгэх"
      subTitle="Шинээр бүртгэх хөтөлбөрийн мэдээлэл сонгоно уу."
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <>
              <Divider mb={10} />
              <SelectField
                name="productId"
                label="Бүтээгдэхүүний нэр"
                placeholder="сонгох"
                options={(productData || [])?.map((item: ISelect) => {
                  return {
                    label: item.name,
                    value: item.refCode,
                  };
                })}
                searchable
              />
              <Divider mt={10} />
            </>
          );
        }}
      </Form>
      <Flex direction="row" justify="space-between" mt={10}>
        <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button loading={loading} key={2} onClick={() => formRef.current?.submit()}>
          Үргэлжлүүлэх
        </Button>
      </Flex>
    </FormLayout>
  );
}
