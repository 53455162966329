import { Box, Button, Divider, Flex, Grid, Text } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import GoogleMap from "../google-map";

const schema = yup.object({});

type Props = {
  contactData?: any;
  onCancel?: any;
};

type IFormData = {
  businessAddress: string;
  district: string;
  khaalgaDugaar: string;
  khashaaDavkhar: string;
  khoroo: string;
  khoroolol: string;
  khotkhonBair: string;
  locationLat: string;
  locationLng: string;
  province: string;
};

export function PaymentContactAddressForm({ contactData, onCancel }: Props) {
  const [data] = React.useState<IFormData>({
    businessAddress: "",
    district: "",
    khaalgaDugaar: "",
    khashaaDavkhar: "",
    khoroo: "",
    khoroolol: "",
    khotkhonBair: "",
    locationLat: "",
    locationLng: "",
    province: "",
    ...contactData?.address,
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md" mt="sm">
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="Нэхэмжлэх хамаарах бизнесийн хаяг" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="province" label="Аймаг, нийслэл" placeholder="Сонгох" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="district" label="Сум, дүүрэг" placeholder="Сонгох" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="khoroo" label="Баг, хороо" placeholder="Сонгох" withAsterisk={false} />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField disabled name="khoroolol" label="Хороолол, гудамж" placeholder="Сонгох" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="khotkhonBair" label="Хотхон, байр" placeholder="Оруулна уу" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField disabled name="khashaaDavkhar" label="Хашаа, давхар" placeholder="Оруулна уу" withAsterisk={false} />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField disabled name="khaalgaDugaar" label="Хаалганы дугаар" placeholder="Оруулна уу" withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TextareaField name="businessAddress" disabled label="Хаягын бичгэн тайлбар" placeholder="Тайлбар оруулна уу." withAsterisk={false} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="Байршил" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <Grid>
                    <Grid.Col span={12}>
                      <Text fz={14} fw={500} c="dimmed">
                        Газрын зураг дээр сонгож тэмдэглэвэл автоматаар уртраг, өргөрөг хадгалагдана.
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextField name="locationLat" label="Өргөрөг" placeholder="47.9179864" disabled withAsterisk={false} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextField name="locationLng" label="Уртраг" placeholder="106.9175798" disabled withAsterisk={false} />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Box h={300} w="100%">
                    <GoogleMap
                      center={{
                        lat: Number(values?.locationLat || 47.9179864),
                        lng: Number(values?.locationLng || 106.9175798),
                      }}
                    />
                  </Box>
                </Grid.Col>
              </Grid>
              <Divider />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => onCancel && onCancel()}>
                  Болих
                </Button>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
