import { ActionIcon, Text } from "@mantine/core";
import { IconCheck, IconMinus } from "@tabler/icons";
import React from "react";
import { ObservationApi } from "../../apis";
import { IActivityLog } from "../../interfaces/IActivityLog";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, Table } from "../table";

type Props = {
  action: any;
};

export function PaymentObservationActionLogList({ action }: Props) {
  const [filters] = React.useState({ repaymentId: action && action[1]?.id });

  const columns = useHeader();

  return <Table name="payment-observation-action-log.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.repayment(data!)} />;
}

const useHeader = (): ColumnType<IActivityLog>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "",
    dataIndex: "logType",
    render: (record) => {
      const isOverdue = record.logType === "OVERDUE";
      return (
        <ActionIcon color={isOverdue ? "red.5" : "green.5"} size="md" radius="xl" variant="filled">
          {isOverdue ? <IconMinus size="1rem" /> : <IconCheck size="1rem" />}
        </ActionIcon>
      );
    },
  },
  {
    title: "Огноо, цаг",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "logType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.logType}
        </Text>
      );
    },
  },
  {
    title: "Холбоотой хэрэглэгч",
    sorter: true,
    dataIndex: "actionUserId",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.actionUser?.lastName[0] || "-"}.{record?.actionUser?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.description || "-"}
        </Text>
      );
    },
  },
];
