import { Button, createStyles, Divider, Flex, Grid, Group, Image, LoadingOverlay, Paper, Text } from "@mantine/core";
import cx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthApi, GeneralApi } from "../../apis";
import BackgroundLine from "../../assets/background-line.webp";
import LoginImage from "../../assets/icon.webp";
import { Form } from "../../components/form";
import { PasswordField } from "../../components/form/password-field";
import { TextField } from "../../components/form/text-field";
import Layout from "../../components/home/layout";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";

const schema = yup.object({
  username: yup.string().max(255, "Хэтэрхий урт байна!").required("Кодоо оруулна уу"),
  password: yup.string().max(255, "Хэтэрхий урт байна!").required("Нууц үгээ оруулна уу"),
});

type IForm = {
  username: undefined | string;
  password: undefined | string;
};

export default function Login() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tabsKey, setTabsKey] = React.useState<string>("2");

  const [data] = React.useState<IForm>({
    username: "",
    password: "",
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      const res = await AuthApi.login(values);
      dispatch(authChange(res));

      const auth = await AuthApi.me();
      const resInit = await GeneralApi.init();

      dispatch(authMe(auth));
      dispatch(initGeneral(resInit));

      navigate("/");
      Message.success("Амжилттай нэвтэрлээ");
      setLoading(false);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <Flex justify="space-between">
          <Flex justify="center" align="center" direction="column" wrap="wrap" style={{ width: "100%" }}>
            <div className={classes.firstText}>
              Үнэ цэн нэмсэн түншлэлийг дэмжих
              <br /> Supply Chain Finance
            </div>
            <img src={LoginImage} alt={"svg"} className={classes.image} />
            <div className={classes.lastText}>Танд амжилт хүсье!</div>
          </Flex>

          <Paper withBorder className={classes.card}>
            <LoadingOverlay visible={loading} />
            <Text fz={30} color={"white"} weight={800}>
              Богд банк
            </Text>

            <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
              {() => {
                return (
                  <>
                    <Flex mih={50} gap="xs" justify="flex-start" align="flex-start" direction="row" wrap="wrap" mt={20}>
                      <Text className={cx(classes.button, { [classes.active]: tabsKey === "2" })} onClick={() => setTabsKey("1")}>
                        Нэгдсэн нэвтрэлт
                        <Divider my="sm" mt={20} />
                      </Text>

                      <Text className={cx(classes.button, { [classes.active]: tabsKey === "1" })} onClick={() => setTabsKey("2")}>
                        Funder бүртгэлээр
                        <Divider my="sm" mt={20} />
                      </Text>
                    </Flex>
                    {tabsKey !== "1" ? (
                      <Grid mt={5}>
                        <Grid.Col h={100} span={12}>
                          <Text fw={400} pb={5} color="white">
                            Хэрэглэгчийн нэрээ оруулна уу: <span className={classes.required}>*</span>
                          </Text>
                          <TextField variant="unstyled" className={classes.input} size="md" name="username" placeholder="Нэвтрэх нэр: " />
                        </Grid.Col>
                        <Grid.Col h={100} span={12}>
                          <Text fw={400} pb={5} color="white">
                            Нууц үг оруулах: <span className={classes.required}>*</span>
                          </Text>
                          <PasswordField variant="unstyled" className={classes.passwordInput} size="md" name="password" placeholder="Нууц үг: " />
                        </Grid.Col>

                        <Grid.Col mt={10} span={12} mb={10}>
                          <Group position="center">
                            <Text color="white">
                              Нууц үг ээ мартсан бол
                              <Link to={"/auth/forgot"}>
                                <Text span c="blue" inherit fw={700} px={5}>
                                  ЭНД ДАРЖ
                                </Text>
                              </Link>
                              сэргээнэ үү.
                            </Text>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <Button variant="gradient" gradient={{ from: "green", to: "#317169" }} type="submit" size="lg" w="100%">
                            НЭВТРЭХ
                          </Button>
                        </Grid.Col>
                      </Grid>
                    ) : (
                      <Grid mt={5}>
                        <Grid.Col h={100} span={12}>
                          <Text fw={400} pb={5} color="white">
                            Хэрэглэгчийн домайн: <span className={classes.required}>*</span>
                          </Text>
                          <TextField variant="unstyled" className={classes.input} size="md" name="domain" placeholder="Домайнаа оруулна уу: " />
                        </Grid.Col>
                        <Grid.Col h={100} span={12}>
                          <Text fw={400} pb={5} color="white">
                            Хэрэглэгчийн нэр: <span className={classes.required}>*</span>
                          </Text>
                          <TextField
                            variant="unstyled"
                            className={classes.input}
                            size="md"
                            name="golomt-username"
                            placeholder="Хэрэглэгчийн нэр оруулна уу: "
                          />
                        </Grid.Col>
                        <Grid.Col h={100} span={12}>
                          <Text fw={400} pb={5} color="white">
                            Нууц үг оруулах: <span className={classes.required}>*</span>
                          </Text>
                          <PasswordField variant="unstyled" className={classes.passwordInput} size="md" name="golomt-password" placeholder="Нууц үг: " />
                        </Grid.Col>

                        <Grid.Col mt={10} span={12} mb={10}>
                          <Group position="center">
                            <Text color="white">Бүртгэлгүй хэрэглэгч бол админдаа хандана уу.</Text>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <Button variant="gradient" gradient={{ from: "green", to: "#317169" }} type="submit" size="lg" w="100%">
                            НЭВТРЭХ
                          </Button>
                        </Grid.Col>
                      </Grid>
                    )}
                  </>
                );
              }}
            </Form>
          </Paper>
        </Flex>
      </div>
    </Layout>
  );
}

const useStyles = createStyles(() => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    padding: "80px 80px 30px 30px",
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    maxWidth: 500,
    maxHeight: 800,
    height: "100%",
    width: "100%",
    position: "relative",
    backgroundColor: "#ffffff19",
    cursor: "pointer",
    border: 0,
    borderRadius: 6,
    overflow: "hidden",
    padding: "35px 40px",
  },
  input: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
  },
  passwordInput: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    borderRadius: 4,
    backgroundColor: "#E8F0Fe",
    height: 42,
  },
  image: {
    height: 500,
    width: 700,
    marginBottom: "2rem",
  },
  firstText: {
    width: "100%",
    color: "#fff",
    fontSize: 24,
    lineHeight: "110%",
    marginBottom: "2rem",
  },
  lastText: {
    color: "#fff",
    fontSize: 24,
    lineHeight: "110%",
  },
  required: {
    color: "red",
  },
  button: {
    display: "block",
    boxSizing: "border-box",
    margin: "0 auto",
    width: "80%",
    maxWidth: "200px",
    textAlign: "center",
    color: "#fff",
    textDecoration: "none",
    letterSpacing: "2px",
    transition: "all 0.3s ease-out",
    fontWeight: 1,
  },
  active: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    transition: "box-shadow 150ms ease, transform 100ms ease",
  },
}));
