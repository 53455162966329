import { Avatar, Badge, Button, Divider, Flex, Input, LoadingOverlay, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { IconBrandTelegram, IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconTrash } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ParticipantApi, ProgramApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IParticipant } from "../../interfaces/IParticipant";
import { IPermissions } from "../../interfaces/IPermissions";
import { IScfProgram } from "../../interfaces/IScfProgram";
import { IReference } from "../../models/General";
import { tugrug } from "../../utils";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { FormLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { BuyerLedProgramReviewCustomerEditForm } from "./form";
import { BuyerLedSmeForm } from "./sme-form";

export function BuyerLedCustomerForm({ data, onSuccess, setResult, rows }: { data: IScfProgram; onSuccess: () => void; setResult?: any; rows?: any }) {
  const params = useParams();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { programParticipantStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<String[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const tableRef = React.useRef<ITableRef>(null);
  const columns = useHeader({
    user,
    programParticipantStatus,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["edit", item as any]);
          break;
        }
        case "review": {
          Dialog.confirm("Та уг бизнесийг хянах илгээхдээ итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.review({ programId: item.programId, smeBusinessId: item.smeBusinessId });
                  Message.success("Амжилттай хянаж зөвшөөрсөн.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "to_review": {
          Dialog.confirm("Та уг бизнесийг хяналтанд илгээхдээ итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.toReview({ programId: item.programId, smeBusinessId: item.smeBusinessId });
                  Message.success("Амжилттай хяналтад илгээлээ.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }

        case "active": {
          Dialog.confirm("Та уг бизнесийг идэвхгүй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.status({ programId: item.programId, smeBusinessId: item.smeBusinessId, isActive: false });
                  Message.success("Амжилттай идэвхгүй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "in_active": {
          Dialog.confirm("Та уг бизнесийг идэвхтэй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.status({ programId: item.programId, smeBusinessId: item.smeBusinessId, isActive: true });
                  Message.success("Амжилттай идэвхтэй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                reload();
                setIsLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }

        case "remove": {
          Dialog.confirm("Та уг бизнесийг устгахдаа илгээхдээ итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setIsLoading(true);
                try {
                  await ParticipantApi.remove(params.id as string, item.smeBusinessId);
                  Message.success("Амжилттай устгасан.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                onSuccess && onSuccess();
                tableRef.current?.reload();
                setIsLoading(false);
                break;
              }

              default:
            }
          });

          break;
        }
      }
    },
  });

  const reload = async () => {
    tableRef.current?.reload();
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <div>
      <FormLayout
        title="Нийлүүлэгчид"
        subTitle="Анкор бизнестэй харилцдаг нийлүүлэгчид"
        extra={[
          <>
            {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isCreate).length > 0 && (
              <Button
                variant="outline"
                onClick={() => {
                  setAction(["sme_add", data as any]);
                }}
                key="sme_add">
                Нийлүүлэгч нэмэх
              </Button>
            )}
          </>,
        ]}>
        <Paper radius="sm" p="lg" withBorder>
          <Flex gap={8}>
            <Input.Wrapper label="Хөтөлбөрт бүртгэсэн нийлүүлэгч тоо:">
              <Text fw={500} color="indigo" fz="sm">
                {`${rows?.count || 0} бизнес`}
              </Text>
            </Input.Wrapper>
            <Input.Wrapper label="Дэд лимитийн нийт дүн:">
              <Text fw={500} color="indigo" fz="sm">
                {tugrug(data?.smeTotalLimit || 0)}
              </Text>
            </Input.Wrapper>
          </Flex>
          <Divider h="lg" mt="lg" />
          <Table
            ref={tableRef}
            name="buyer-led-program-sme-participants.list"
            filters={{ productType: "BUYER_LED", programId: data?.id }}
            columns={columns}
            onResult={(setData: string) => setResult(setData)}
            loadData={(data) => ProgramApi.participantList(data!)}
          />
        </Paper>
      </FormLayout>
      <Modal opened={action[0] === "sme_add"} onClose={() => setAction([])} withCloseButton={false} size="80%" centered>
        <BuyerLedSmeForm
          action={action}
          setAction={setAction}
          reload={() => {
            tableRef.current?.reload();
            onSuccess && onSuccess();
          }}
        />
      </Modal>
      <Modal
        opened={action[0] === "edit"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        centered
        size="60%">
        <BuyerLedProgramReviewCustomerEditForm
          action={action}
          onCancel={() => {
            setAction([]);
          }}
          reload={() => {
            reload();
            setAction([]);
          }}
        />
      </Modal>
    </div>
  );
}

const useHeader = ({
  programParticipantStatus,
  onClick,
  user,
}: {
  user: any;
  programParticipantStatus: IReference[];
  onClick: (key: string, record: IParticipant) => void;
}): ColumnType<IParticipant>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {record?.participantStatus === "REGISTERED" && (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                <IconEdit />
              </Button>
            </Tooltip>
          )}

          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isReview).length >
              0 &&
              record?.registrationStatus !== "REJECTED" &&
              record?.registrationStatus !== "DRAFT" &&
              record?.participantStatus === "IN_REVIEW" && (
                <Tooltip label="Хянах" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("review", record)}>
                    <IconEyeCheck />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isEdit).length >
              0 &&
              record?.participantStatus === "REGISTERED" &&
              record?.programStatus !== "CLOSED" &&
              record?.programStatus !== "CANCELLED" &&
              record?.registrationStatus !== "DRAFT" &&
              record?.registrationStatus !== "REJECTED" && (
                <Tooltip label="Хяналтад илгээх" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("to_review", record)}>
                    <IconBrandTelegram />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_BL" && c.isEdit).length >
              0 &&
              record?.participantStatus === "REVIEWED" &&
              record?.programStatus === "ACTIVE" &&
              record?.participantStatus === "INACTIVE" &&
              record?.registrationStatus === "REVIEWED" && (
                <Tooltip label="Идэвхтэй болгох" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                    <IconCircleCheck />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_STATUS_CHANGE_BL" && c.isEdit).length >
              0 &&
              record?.participantStatus === "REVIEWED" &&
              record?.programStatus === "ACTIVE" &&
              record?.participantStatus === "ACTIVE" &&
              record?.registrationStatus === "REVIEWED" && (
                <Tooltip label="Идэвхгүй болгох" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                    <IconCircleMinus />
                  </Button>
                </Tooltip>
              )}
          </>
          <>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_SME_MANAGEMENT_BL" && c.isDelete).length >
              0 &&
              record?.participantStatus === "REGISTERED" && (
                <Tooltip label="Устгах" position="bottom">
                  <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
                    <IconTrash />
                  </Button>
                </Tooltip>
              )}
          </>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "ТТД",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            border: `${(programParticipantStatus || []).find((c: any) => c?.code === record.participantStatus)?.color} 1px solid`,
            color: `${(programParticipantStatus || []).find((c: any) => c?.code === record.participantStatus)?.color}`,
          }}>
          {programParticipantStatus?.find((item: IReference) => item.code === record.participantStatus)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Партнер нэр",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.partner?.logo !== null ? record?.partner?.logo : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#317169">
              {record?.partner?.refCode || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.partner?.businessName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес нэр",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч санхүү",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={280}>
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.finUser?.lastName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.finUser?.firstName || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч дэд лимит",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {tugrug(record?.availableAmount || 0)}
        </Text>
      );
    },
  },
  {
    title: "Нийлүүлэгч орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeInAcc?.icon === null || !record?.smeInAcc ? noimage : record?.smeInAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.smeInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.smeOutAcc?.icon === null || !record?.smeOutAcc ? noimage : record?.smeOutAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.smeOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.smeOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorInAcc?.icon === null || !record?.anchorInAcc ? noimage : record?.anchorInAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.anchorInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorInAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Анкорын зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap={8} w={200}>
          <Avatar src={record?.anchorOutAcc?.icon === null || !record?.anchorOutAcc ? noimage : record?.anchorOutAcc?.icon} />
          <Text w={220}>
            <Text size="sm" weight={500} c="#868E96">
              {record?.anchorOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C">
              {record?.anchorOutAcc?.number || "-"}
            </Text>
          </Text>
        </Flex>
      );
    },
  },
];
