import { Box, Divider, Grid, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { Form } from "../form";

type Props = {
  item: any;
};

type IFormData = {
  name: string;
};

const schema = yup.object({
  name: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

export function AncorDetailInformation({ item }: Props) {
  const { golomtLegalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    name: "",
  });

  const onSubmit = () => {};

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid sx={{ position: "relative" }}>
            <Grid.Col span={12}>
              <Divider color="gray" mt="md" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ҮНДСЭН МЭДЭЭЛЭЛ" />
            </Grid.Col>

            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Регистр/Татвар төлөгчийн дугаар:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.regNumber || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Харилцагчийн нэр:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.clientName || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Харилцагчийн код:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.clientCode || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Аж ахуй нэгжийн хэлбэр:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {(golomtLegalEntityTypes || []).find((c: IReference) => c.code === item?.legalEntityType)?.name || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Партнерийн код:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.refCode || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Партнерийн нэр:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.businessName || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col span={12}>
              <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХОЛБОО БАРИХ" />
            </Grid.Col>

            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Вейб сайтын хаяг:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.website || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="И-мэйл хаяг №1:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.email || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col xs={12} sm={6} md={6} xl={4}>
              <Input.Wrapper label="Утасны дугаар №1:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.phone || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col span={12}>
              <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ХАЯГ БАЙРШИЛ" />
            </Grid.Col>

            <Grid.Col span={12}>
              <Input.Wrapper label="Хаягийн бичгэн тайлбар:">
                <Box style={{ alignItems: "center" }}>
                  <Text color="indigo" sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                    {item?.address || "-"}
                  </Text>
                </Box>
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}
