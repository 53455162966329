import { Alert, Button, CloseButton, Divider, Flex, Grid, LoadingOverlay, Text } from "@mantine/core";
import { useState } from "react";
import * as yup from "yup";
import { AuthApi, RequestApi } from "../../apis";
import { tugrug } from "../../utils";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { OtpField } from "../form/otp-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  pin: yup.string().min(6, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  pin: any;
};

export function ScfRequestPinForm({
  action,
  onCancel,
  title,
  subTitle,
  reload,
  setPinCheck,
  pinCheck,
}: {
  pinCheck: any;
  action: any[];
  onCancel: any;
  title: string;
  subTitle: string;
  reload: any;
  setPinCheck: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const [data] = useState<IFormData>({
    pin: null,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await AuthApi.checkPin({ pin: values?.pin });
      if (pinCheck[1]?.finance === "CONFIRM") {
        await RequestApi.respondSupplier(action[1]?.id, {
          respondDesc: pinCheck[1].respondDesc,
          approvedAmount: pinCheck[1].approvedAmount,
          isApproved: pinCheck[1]?.finance === "CONFIRM" ? true : false,
        });
        Message.success("Санхүүжилтын хүсэлтийг амжилттай зөвшөөрсөн.");
      } else {
        await RequestApi.respondSupplier(action[1]?.id, {
          respondDesc: pinCheck[1].respondDesc,
          isApproved: pinCheck[1]?.finance === "CONFIRM" ? true : false,
        });
        Message.success("Санхүүжилтын хүсэлтийг татгалзсан.");
      }
      reload();
      setLoading(false);
      onCancel([]);
      setPinCheck([]);
    } catch (error) {
      Message.error((error as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => setPinCheck([])} />]}>
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12}>
                {pinCheck[1]?.finance === "CONFIRM" ? (
                  <Alert
                    styles={{ message: { color: "#44566c", fontWeight: 500, textAlign: "justify" } }}
                    radius="md"
                    color="indigo"
                    withCloseButton={false}
                    title="Таны анхааралд">
                    Та Supplier led санхүүжилтийн {`${action[1]?.refCode}`} хүсэлтийн кодтой, {`${tugrug(pinCheck[1]?.approvedAmount)}`}-ийн санхүүжилтийг
                    зөвшөөрөх гэж байна.
                  </Alert>
                ) : (
                  <Alert
                    styles={{ message: { color: "#44566c", fontWeight: 500, textAlign: "justify" } }}
                    radius="md"
                    color="indigo"
                    withCloseButton={false}
                    title="Таны анхааралд">
                    Та Supplier led санхүүжилтийн {`${action[1]?.refCode}`} хүсэлтийн кодтой санхүүжилтийг татгалзах гэж байна.
                  </Alert>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <OtpField name="pin" label="ПИН код" placeholder="" required />
              </Grid.Col>

              <Grid.Col span={12}>
                <Text component="p" fz="sm" fw={500}>
                  Танд амжилт хүсье!
                </Text>
              </Grid.Col>
            </Grid>
            <Divider my="lg" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => setPinCheck([])}>
                Болих
              </Button>
              <Flex gap="md">
                <Button variant="outline" type="submit">
                  Баталгаажуулах
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
