import { Alert, Box, Button, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import React from "react";
import * as yup from "yup";
import useUser from "../../hooks/user";
import { numberRegex, tugrug } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { RadioGroupField } from "../form/radio-group-field";
import { TextareaField } from "../form/textarea-field";

const schema = yup.object({
  approvedAmount: yup.string().when("finance", {
    is: "CONFIRM",
    then: yup
      .string()
      .min(1, "Хэтэрхий бага утга байна!")
      .max(16, "Хэтэрхий их утга байна!")
      .matches(numberRegex, "Тоо оруулна уу!")
      .required("Заавал бөглөнө үү!")
      .nullable(),
  }),
  respondDesc: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  finance: string;
  respondDesc: string;
  isApproved: boolean;
  approvedAmount: string;
};

const financeType = [
  {
    name: "Санхүүжилтийг зөвшөөрөх",
    code: "CONFIRM",
  },
  {
    name: "Санхүүжилтийг татгалзах",
    code: "REJECT",
  },
];

export function ScfSupplierFinanceDecisionForm({
  action,
  onTabChange,
  onSubmit: _onSubmit,
}: {
  action: any;
  onTabChange: any;
  onSubmit: (values: any) => void;
}) {
  const nowMoment = new Date();
  const { user } = useUser();
  const [data] = React.useState<IFormData>({
    isApproved: false,
    approvedAmount: "",
    respondDesc: "",
    finance: "",
  });

  const onSubmit = (values: any) => {
    _onSubmit(values);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <Grid>
                <Grid.Col span={12} mt="xs">
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="САНХҮҮЖИЛТ АВАГЧ ТАЛ" />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч партнер:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.partner?.refCode || "-"}, {action[1]?.invoice?.senderBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.regNumber || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Санхүүжилт авагч бизнес:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action[1]?.invoice?.senderBusiness?.refCode || "-"}, {action[1]?.invoice?.senderBusiness?.profileName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн огноо:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {dateSecFormat(nowMoment)}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрлэсэн ажилтан:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {user?.firstName || "-"} {user?.lastName || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Шийдвэрийн дугаар:">
                    <Box style={{ alignItems: "center" }}>
                      <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                        {action?.program?.resolutionNo || "-"}
                      </Text>
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                {action[1]?.program?.programStatus === "ACTIVE" ? (
                  <>
                    <Grid.Col span={12}>
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="ШИЙДВЭРИЙН МЭДЭЭЛЭЛ" />
                    </Grid.Col>

                    <Grid.Col span={12}>
                      <RadioGroupField
                        name="finance"
                        label=""
                        options={financeType.map((c) => ({
                          label: c.name,
                          value: c.code,
                        }))}
                      />
                    </Grid.Col>
                    {values.finance === "" && (
                      <Grid.Col span={6}>
                        <Alert
                          styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                          icon={<IconAlertCircle size="1rem" />}
                          radius="md"
                          color="yellow"
                          withCloseButton={false}
                          title="Таны анхааралд">
                          Та санхүүжилтийн хүсэлтийг зөвшөөрөх эсэхээ чагталж сонгоно уу.
                        </Alert>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action[1]?.program?.disburseAcc?.number || "-"}, {action[1]?.program?.disburseAcc?.name},{" "}
                              {action[1]?.program?.disburseAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын дансны үлдэгдэл:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {tugrug(action[1]?.program?.disburseAcc?.balance || "0")}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын данс шаардлага:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action[1]?.program?.disburseAcc?.balanceAcceptable ? "Тийм" : "Үгүй"}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <>
                        <Grid.Col span={4}>
                          <NumberCurrencyField name="approvedAmount" label="Санхүүжилт олгох дүн:" placeholder="Санхүүжүүлэх дүн" required={true} />
                          <Text c="dimmed" fz="xs" fw={500} style={{ color: "#FA5252" }}>
                            {tugrug(action[1]?.requestedAmount || 0)} {"Та санхүүжилтийн хүсэлтийн дүнгээс бага дүн оруулах боломжтой."}
                          </Text>
                        </Grid.Col>
                      </>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Банкны шимтгэл:">
                          <Box style={{ alignItems: "center" }}>
                            {!values.approvedAmount ? (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(0)}
                              </Text>
                            ) : (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(values.approvedAmount / 100 + action[1]?.product?.suppDisbursementFee + action[1]?.appFee)}
                              </Text>
                            )}
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Өргөдлийн хураамж:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {tugrug(action[1]?.appFee || "0")}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Олголтын шимтгэл:">
                          <Box style={{ alignItems: "center" }}>
                            {!values?.approvedAmount ? (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(0)}
                              </Text>
                            ) : (
                              <Text
                                variant="gradient"
                                gradient={{ from: "indigo", to: "cyan" }}
                                sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                                fz="sm"
                                fw={600}>
                                {tugrug(values.approvedAmount / 100 + action[1]?.product?.suppDisbursementFee)}
                              </Text>
                            )}
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Санхүүжилт авах данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action[1]?.invoice?.senderAcc?.number}, {action[1]?.invoice?.senderAcc?.name}, {action[1]?.invoice?.senderAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="SCF орлого төвлөрүүлэх данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action[1]?.program?.feeAcc?.number || "-"}, {action[1]?.program?.feeAcc?.name || "-"},{" "}
                              {action[1]?.program?.feeAcc?.bankName || "-"}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}

                    {values.finance === "CONFIRM" && (
                      <Grid.Col span={4}>
                        <Input.Wrapper label="Эргэн төлөх данс:">
                          <Box style={{ alignItems: "center" }}>
                            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan" }} sx={{ fontFamily: "Greycliff CF, sans-serif" }} fz="sm" fw={600}>
                              {action[1]?.program?.repayAcc?.number || "-"}, {action[1]?.program?.repayAcc?.name}, {action[1]?.program?.repayAcc?.bankName}
                            </Text>
                          </Box>
                        </Input.Wrapper>
                      </Grid.Col>
                    )}
                    <Grid.Col span={12}>
                      <CheckboxField name="finance_board" label="">
                        {values.finance_board ? "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан" : "Санхүүжилтийн хүсэлтийн мэдэээллийг хянасан"}
                      </CheckboxField>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextareaField name="respondDesc" label="Тайлбар:" placeholder="Тайлбар энд бичих" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} />
                    </Grid.Col>
                  </>
                ) : (
                  <Grid.Col span={6}>
                    <Alert
                      styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                      icon={<IconAlertCircle size="1rem" />}
                      radius="md"
                      color="yellow"
                      withCloseButton={false}
                      title="Таны анхааралд">
                      Хөтөлбөр идэвхгүй байна.
                    </Alert>
                  </Grid.Col>
                )}
              </Grid>
              <Flex direction="row" justify="space-between">
                <Button
                  variant="default"
                  onClick={() => {
                    onTabChange("3");
                  }}>
                  Буцах
                </Button>
                <Flex gap="md">
                  {values.finance === "REJECT" && (
                    <Button variant="filled" color="red" disabled={!values.finance_board} type="submit">
                      Татгалзах
                    </Button>
                  )}
                  {values.finance === "CONFIRM" && (
                    <Button variant="filled" disabled={!values.finance_board} type="submit">
                      Зөвшөөрөх
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          );
        }}
      </Form>
    </>
  );
}
