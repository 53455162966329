import { Avatar, Button, Checkbox, CloseButton, Divider, Flex, Grid, Group, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { RequestApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IBusiness } from "../../interfaces/IBusiness";
import { IGeneral } from "../../interfaces/IGeneral";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { RadioGroupField } from "../form/radio-group-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  clientType: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  status?: string;
  partnerId: string;
  clientType: string;
  regNumber: string;
  businessName: string;
  refCode: string;
};

const status = [
  { code: "success", name: "Зөв таарч байна" },
  { code: "not", name: "Таарахгүй байна" },
];

export function RequestForm({ action, onCancel, reloadTab }: { action: any; onCancel: () => void; reloadTab: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { clientTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader();

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await RequestApi.responds({ partnerId: action && action[1]?.partnerId, clientType: values.clientType });
      reloadTab();
      onCancel && onCancel();
      Message.success(
        "Та харилцагчийг санхүүжилтийн үйлчилгээнд хамрах боломжтой болгож зөвшөөрлөө. Банктай харилцаж ажиллах ажилтанд эрх зөвшөөрөх үйлдэл хийнэ үү.",
      );
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const { data: userData, isLoading } = useSwr(
    `/api/client/user_info?registerNo=${action && action[1]?.financeRoleStatus}`,
    async () => {
      if (!action[1]?.partner?.regNumber) {
        return [];
      }

      let res = await RequestApi.info(action && action[1]?.partner?.regNumber);
      return res;
    },
    {},
  );

  if (isLoading || loading) return <LoadingOverlay visible={isLoading || loading} />;

  return (
    <Form
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        ...userData,
        ...action[1],
        status: "",
        partnerId: "",
        clientType: "",
        businessName: action[1]?.partner?.businessName,
        refCode: action[1]?.partner?.refCode,
        regNumber: action[1]?.partner?.regNumber,
        infoRegNumber: userData?.regNumber,
        infoCorpKey: userData?.corpKey,
        infoLegalEntityType: userData?.legalEntityType,
        infoCorpName: userData?.corpName,
      }}>
      {({ values }) => {
        return (
          <FormLayout
            title="Анкорын урилга зөвшөөрөх"
            subTitle="Анкор харилцагчаас хөтөлбөрт хамрагдахаар Finance модулийн тохиргоо хийсэн"
            my={0}
            extra={[
              <CloseButton
                key="cancel"
                onClick={() => {
                  onCancel && onCancel();
                }}
              />,
            ]}>
            <Divider />

            <Flex direction="column">
              <Grid>
                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16} mt="xs">
                    Харилцагчийн банкны бүртгэл дэх мэдээлэл
                  </Text>
                </Grid.Col>

                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="infoRegNumber" disabled label="Татвар төлөгчийн дугаар: " placeholder="Нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="infoCorpName" disabled label="Харилцагчийн нэр: " placeholder="Нэр: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="infoCorpKey" disabled label="Харилцагчийн код: " placeholder="Код: " withAsterisk={false} />
                </Grid.Col>

                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="infoLegalEntityType" disabled label="Аж ахуй нэгжийн хэлбэр: " placeholder="ААН: " withAsterisk={false} />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16}>
                    DeHUB платформ дахь мэдээлэл
                  </Text>
                </Grid.Col>

                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="regNumber" disabled label="Татвар төлөгчийн дугаар: " placeholder="ТТД: " withAsterisk={false} />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="refCode" label="Партнер код: " placeholder="Код: " withAsterisk={false} disabled />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <TextField name="businessName" label="Партнер нэр: " placeholder="Нэр: " withAsterisk={false} disabled />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider />
                  <Table name="businesses.list" columns={columns} dataSource={action[1]?.businesses || []} pagination={false} />
                  <Divider />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Text fw={500} color="gray" size={16}>
                    Баталгаажилт хэсэг
                  </Text>
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <RadioGroupField
                    label="Платформ дээрх мэдээлэл нь банкны мэдээлэлтэй"
                    name="status"
                    options={(status || []).map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={4}>
                  <RadioGroupField
                    label="Харилцагчийн төрөл"
                    name="clientType"
                    options={(clientTypes || []).map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </Grid.Col>
              </Grid>
            </Flex>
            <Divider mt="md" />
            <Flex mt={20} direction="row" justify="space-between">
              <Button variant="default" onClick={() => {}}>
                Болих
              </Button>
              <Flex gap="md">
                <Button type="submit" disabled={values.status !== "success"}>
                  Урилга зөвшөөрөх
                </Button>
              </Flex>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useHeader = (): ColumnType<IBusiness>[] => [
  {
    title: "#",
    dataIndex: "index",
    render: (_record, index) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 30 }}>
          {`${index + 1}`}
        </Text>
      );
    },
  },
  {
    title: "Зураг",
    render: (record) => (
      <Group spacing="sm">
        <Avatar size={40} src={record?.logo !== null ? record?.logo : noimage} radius={5} />
      </Group>
    ),
  },
  {
    title: "Анкор бизнес код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Анкор бизнес нэр",
    dataIndex: "profileName",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Buyer эсэх",
    render: (record) => {
      const isBuyer = record.type === "BUYER";
      return (
        <Checkbox
          checked={isBuyer ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {isBuyer ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Supplier эсэх",
    render: (record) => {
      const isSupplier = record.type === "SUPPLIER";
      return (
        <Checkbox
          checked={isSupplier ? true : false}
          label={
            <Text size="sm" weight={500} c="#44566C">
              {isSupplier ? "Тийм" : "Үгүй"}
            </Text>
          }
          size="sm"
          onChange={() => {}}
        />
      );
    },
  },
];
