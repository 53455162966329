import { Avatar, Badge, Button, Checkbox, Drawer, Flex, Input, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconBrandTelegram, IconCircleCheck, IconCircleMinus, IconEdit, IconEyeCheck, IconInfoSquare, IconPlus, IconSearch } from "@tabler/icons";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ProductsApi } from "../../../apis";
import noimage from "../../../assets/no-image.png";
import { ActiveForm } from "../../../components/buyer-product/active-form";
import { RequestPinForm } from "../../../components/buyer-product/request-pin-form";
import { PageFilter, PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { IAuth } from "../../../interfaces/IAuth";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IPermissions } from "../../../interfaces/IPermissions";
import { IProduct } from "../../../interfaces/IProduct";
import { IReference } from "../../../models/General";
import { dateSecFormat } from "../../../utils/date";
import { BuyerProductDetail } from "./detail";

export function BuyerProductList() {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const ref = useRef<ITableRef>(null);
  const [query, setQuery] = useState("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { productTypes, productCategories, productRegistrationStatus, productStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<string[]>([]);

  const columns = useHeader({
    productTypes,
    productCategories,
    productRegistrationStatus,
    productStatus,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "edit": {
          navigate(`/scf-dcf-product/dcf-product/edit/${record.id}/1`);
          break;
        }
        case "detail": {
          setAction(["DETAIL", record as any]);
          break;
        }
        case "active": {
          setPayload(record as any);
          setAction(["ACTIVE"]);
          break;
        }
        case "in_active": {
          setPayload(record as any);
          setAction(["INACTIVE"]);
          break;
        }

        case "is_compolete": {
          setAction(["ISCOMPOLETE", record as any]);
          break;
        }
        case "control": {
          setAction(["DETAIL", record as any, "CONTROL"]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref?.current?.reload();
    setPayload([]);
  };

  return (
    <>
      <PageLayout
        title="Buyer LED SCF"
        subTitle="Buyer LED SCF бүтээгдэхүүний жагсаалт"
        breadcrumb={breadcrumbs}
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isCreate).length > 0 && (
              <Button size="sm" key={0} onClick={() => navigate("/scf-dcf-product/dcf-product/new")} variant="outline" leftIcon={<IconPlus />}>
                Бүтээгдэхүүн
              </Button>
            )}
          </div>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <PageFilter
            left={[
              <Tooltip label="Бүтээгдэхүүний нэрээр хайх" color="indigo" position="top-end" withArrow key={2}>
                <Input
                  value={query}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  placeholder="Хайлт хийх"
                  rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
                />
              </Tooltip>,
            ]}
          />
          <Table
            ref={ref}
            name="dcf-product.list"
            columns={columns}
            filters={{ query: queryDebounced, type: "BUYER_LED" }}
            loadData={(data) => ProductsApi.list(data!)}
          />
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "ISCOMPOLETE"} onClose={() => setAction([])} withCloseButton={false}>
        <RequestPinForm
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          onCancel={onCancel}
          reload={reload}
          type={"BUYER_LED"}
        />
      </Modal>
      <Modal opened={action[0] === "INACTIVE" || action[0] === "ACTIVE"} onClose={() => setAction([])} withCloseButton={false} centered>
        <ActiveForm
          title="Funder систем"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
          action={action}
          onCancel={onCancel}
          payload={payload}
          reload={reload}
        />
      </Modal>

      <Drawer
        opened={action[0] === "DETAIL"}
        onClose={() => setAction([])}
        withCloseButton={false}
        position="right"
        size="80%"
        styles={{ drawer: { overflowY: "scroll" } }}>
        <BuyerProductDetail action={action} reload={reload} onCancel={onCancel} />
      </Drawer>
    </>
  );
}

const useHeader = ({
  onClick,
  productTypes,
  productCategories,
  productRegistrationStatus,
  productStatus,
  user,
}: {
  user: any;
  onClick: (key: string, record: IProduct) => void;
  productTypes: IReference[];
  productCategories: IReference[];
  productRegistrationStatus: IReference[];
  productStatus: IReference[];
}): ColumnType<IProduct>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap="sm">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_STATUS_CHANGE_BL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.productStatus === "INACTIVE" && (
              <Tooltip label="Идэвхтэй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                  <IconCircleCheck />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_STATUS_CHANGE_BL").length > 0 &&
            record.registrationStatus === "REVIEWED" &&
            record.productStatus === "ACTIVE" && (
              <Tooltip label="Идэвхгүй болгох" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                  <IconCircleMinus />
                </Button>
              </Tooltip>
            )}
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isEdit).length > 0 &&
            record.isCompleted &&
            !record.isSent && (
              <Tooltip label="Хяналтад илгээх" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("is_compolete", record)}>
                  <IconBrandTelegram />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isEdit).length > 0 &&
            record.registrationStatus === "DRAFT" && (
              <Tooltip label="Засварлах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                  <IconEdit />
                </Button>
              </Tooltip>
            )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isView).length > 0 && (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "PRODUCT" && c.code === "SCF_PRODUCT_MANAGEMENT_BL" && c.isReview).length > 0 &&
            record.registrationStatus === "REGISTERED" &&
            record.effectiveFrom === null &&
            record.productStatus === "INACTIVE" && (
              <Tooltip label="Хянах" position="bottom">
                <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("control", record)}>
                  <IconEyeCheck />
                </Button>
              </Tooltip>
            )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: 40,
  },
  {
    title: "SCF код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text style={{ width: 250 }} size="sm" weight={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "productStatus",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color={(productStatus || []).find((item: IReference) => item.code === record.productStatus)?.color}>
          {(productStatus || []).find((item: IReference) => item.code === record.productStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "type",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {(productTypes || []).find((item: IReference) => item.code === record.type)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Ангилал",
    sorter: true,
    dataIndex: "category",
    render: (record) => {
      return (
        <Text style={{ width: 150 }} size="sm" weight={500} c="#44566C">
          {(productCategories || []).find((item: IReference) => item.code === record.category)?.name}
        </Text>
      );
    },
  },
  {
    title: "Буцаан дуудах",
    sorter: true,
    dataIndex: "recourseTerm",
    render: (record) => {
      return (
        <Checkbox
          checked={record.recourseTerm}
          label={
            record.recourseTerm ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Supplier санхүүжих",
    sorter: true,
    dataIndex: "borrowerType",
    render: (record) => {
      return (
        <Checkbox
          checked={record.borrowerType === "SUPPLIER_ONLY" || record.borrowerType === "BUYER"}
          label={
            record.borrowerType === "SUPPLIER_ONLY" || record.borrowerType === "BUYER" ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Buyer санхүүжих",
    sorter: true,
    dataIndex: "borrowerType",
    render: (record) => {
      return (
        <Checkbox
          checked={record.borrowerType === "BUYER"}
          label={
            record.borrowerType === "BUYER" ? (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text style={{ width: 100 }} size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Бүртгэл",
    sorter: true,
    dataIndex: "registrationStatus",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          color={(productRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.color}>
          {(productRegistrationStatus || []).find((item: IReference) => item.code === record.registrationStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text style={{ width: 150 }} size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.regUser?.avatar !== null && record?.regUser !== null ? record?.regUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.regUser?.lastName[0]}.{record?.regUser?.firstName}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Хянасан огноо",
    sorter: true,
    dataIndex: "reviewedDate",
    render: (record) => {
      return (
        <Text style={{ width: 150 }} size="sm" weight={500} c="#44566C">
          {dateSecFormat(record.reviewedDate)}
        </Text>
      );
    },
  },
  {
    title: "Хянасан",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.reviewedUser?.avatar !== null && record?.reviewedUser !== null ? record?.reviewedUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.reviewedUser?.lastName[0] || "-"}.{record?.reviewedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Санхүүжилтийн бүтээгдэхүүн",
  },
  {
    label: "Buyer LED бүтээгдэхүүн",
  },
];
