import { Carousel } from "@mantine/carousel";
import { Box, createStyles, Divider, Flex, Grid, Group, Image, LoadingOverlay, Paper, Tabs, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { IconInfoSquare, IconStar, IconStarHalf } from "@tabler/icons";
import React from "react";
import useSwr from "swr";
import { PartnerApi } from "../../apis";
import banner from "../../assets/banner.png";
import { AncorDetailAccountList } from "./account-list";

export function AncorDetailSupplierForm({ action }: { action: any }) {
  const { classes } = useStyles();
  const [tabKey, setTabKey] = React.useState<string | null>("INFORMATION");

  const { data: supplierData, isLoading } = useSwr(
    `/api/client/partner/business/data/supplier/${action && action[1]}`,
    async () => {
      if (action && !action[1]) {
        return [];
      }
      let res = await PartnerApi.businessGet(action && action[1]);
      return res;
    },
    {},
  );

  if (isLoading) return <LoadingOverlay visible />;

  return (
    <Flex direction="column" gap="md">
      <Carousel height={250} withIndicators w="100%" style={{ background: "#317169" }}>
        {supplierData?.profileBanners?.length !== 0 ? (
          <>
            {(supplierData?.profileBanners || []).map((item: any, key: number) => (
              <Carousel.Slide key={key}>
                <Image src={item?.url} />
              </Carousel.Slide>
            ))}
          </>
        ) : (
          <Carousel.Slide>
            <Image src={banner} />
          </Carousel.Slide>
        )}
      </Carousel>
      <Group>
        <Paper radius={100} pos="absolute" ml={25} p={5} mt={-60}>
          <Image height={150} width={150} src={`${supplierData?.logo || "https://cdn-icons-png.flaticon.com/512/147/147144.png"}`} radius={100} />
        </Paper>
        <Flex ml={210} pos="absolute" align="flex-start" direction="column" wrap="wrap" mt={-120}>
          <Flex align="flex-start" direction="column">
            <Text color="white" lh="xl" fw={600} size="lg">
              {supplierData?.refCode}
            </Text>
            <Text color="white" fw={600} lh="xl" size="lg">
              {supplierData?.profileName || "-"}
            </Text>
          </Flex>
          <Box>
            <Flex align={"center"}>
              <IconStar color="yellow" fill="yellow" />
              <IconStar color="yellow" fill="yellow" />
              <IconStar color="yellow" fill="yellow" />
              <IconStar color="yellow" fill="yellow" />
              <IconStarHalf color="yellow" />
              <Text color="white">(11248)</Text>
            </Flex>
          </Box>
        </Flex>
      </Group>
      <Paper radius="sm" p="xs" withBorder className={classes.info}>
        <Tabs value={tabKey} onTabChange={(e) => setTabKey(e)}>
          <Tabs.List>
            <Tabs.Tab value="INFORMATION" icon={<IconInfoSquare color={tabKey === "INFORMATION" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Мэдээлэл
              </Text>
            </Tabs.Tab>
            {/* <Tabs.Tab value="PROGRAM" icon={<IconSettings color={tabKey === "PROGRAM" ? "#317169" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Хөтөлбөр
              </Text>
            </Tabs.Tab> */}
          </Tabs.List>
          <Tabs.Panel value="INFORMATION">
            <Grid gutter="md" p={10}>
              <Grid.Col span={3}>
                <Box className={classes.gridBox}>
                  <Flex direction="row" justify="space-between">
                    <Text fw={500} mb="xs">
                      {"Нийлүүлэлт"}
                    </Text>
                  </Flex>
                  <Divider color="gray.4" className={classes.label} mb="xs" />
                  <Text fw={500}>Салбар</Text>
                  <Text size="md" weight={500} c="#44566C">
                    {supplierData?.businessSector?.name || "-"}
                  </Text>
                  <Text fw={500}>Дэд салбар</Text>
                  <Text size="md" weight={500} c="#44566C">
                    {supplierData?.businessSubSector?.name || "-"}
                  </Text>
                  <Text fw={500}>Борлуулалтын менежер</Text>
                  <Text size="md" weight={500} c="#44566C">
                    {supplierData?.finUser?.lastName ? supplierData?.finUser?.lastName[0] : ""}
                    {supplierData?.finUser?.lastName ? "." : ""} {supplierData?.finUser?.firstName || "-"}
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={9}>
                <Box className={classes.gridBox}>
                  <Flex direction="row" justify="space-between">
                    <Text fw={500} mb="xs">
                      {"Мэдэгдэл"}
                    </Text>

                    <Flex gap="md">
                      <UnstyledButton>
                        <Tooltip label="Тун удахгүй" color="blue" withArrow>
                          <Text fw={500} mb="xs" c="dimmed">
                            {"Бүгдийг үзэх"}
                          </Text>
                        </Tooltip>
                      </UnstyledButton>
                    </Flex>
                  </Flex>
                  <Divider color="gray.4" className={classes.label} mb="xs" />
                  <Text size="sm" weight={500} c="#44566C">
                    {supplierData?.profileInfo}
                  </Text>
                </Box>
              </Grid.Col>

              <Grid.Col span={12}>
                <Box className={classes.gridBox}>
                  <Text fw={500} mb="xs">
                    {"Дансны тохиргоо"}
                  </Text>
                  <Divider color="gray.4" className={classes.label} mb="xs" />
                  <AncorDetailAccountList data={supplierData?.accounts || []} />
                </Box>
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
          <Tabs.Panel value="BUYER">
            <></>
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  info: {
    marginTop: 45,
    marginLeft: 10,
    marginRight: 10,
  },

  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    padding: 20,
    marginTop: 10,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
  },
}));
