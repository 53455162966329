import { Button, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { ReminderApi } from "../../apis";
import useUser from "../../hooks/user";
import { IGeneral } from "../../interfaces/IGeneral";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { RadioGroupField } from "../form/radio-group-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  reminderType: yup.string().required("Заавал бөглөнө!").nullable(),
  reminderLevel: yup.string().required("Заавал бөглөнө!").nullable(),
  title: yup.string().max(1000, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  clientText: yup.string().max(1000, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  compromisable: yup.string().required("Заавал бөглөнө!").nullable(),
});

type Props = {
  onCancel?: () => void;
  action: any[];
  reload?: any;
};

type IFormData = {
  repaymentId: string;
  email: string;
  reminderType: string;
  reminderLevel: string;
  title: string;
  clientText: string;
  compromisable: string;
  repaymentRefCode: string;
};

const productOverDueLimitTypes = [
  {
    code: "YES",
    name: "Тийм",
  },
  {
    code: "NO",
    name: "Үгүй",
  },
];

export function PaymentMessageForm({ action, onCancel, reload }: Props) {
  const { user } = useUser();
  const { reminderTypes, reminderLevels } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    repaymentId: "",
    email: "",
    reminderType: "",
    reminderLevel: "",
    title: "",
    clientText: "",
    compromisable: "",
    repaymentRefCode: "",
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await ReminderApi.email({
        repaymentId: action[1]?.id,
        email: action[1]?.payerStaff?.email,
        reminderType: values.reminderType,
        reminderLevel: values.reminderLevel,
        title: values.title,
        clientText: values.clientText,
        repaymentRefCode: action[1]?.refCode,
        compromisable: values.compromisable === "YES" ? true : false,
      });
      Message.success("Амжилттай илгээсэн.");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout
      title={`${action[1]?.payerBusiness?.refCode}, ${action[1]?.payerBusiness?.profileName}`}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <Divider mb="lg" />
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {() => {
          return (
            <>
              <Flex sx={{ position: "relative" }} direction="column" gap="md">
                <LoadingOverlay visible={loading} />
                <Grid>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Партнерийн нэр">
                      <Text color="indigo" fw={600}>
                        {action[1]?.payerBusiness?.partner?.refCode || "-"}, {action[1]?.payerBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Бизнесийн нэр">
                      <Text color="indigo" fw={600}>
                        {action[1]?.payerBusiness?.refCode || "-"}, {action[1]?.payerBusiness?.profileName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Input.Wrapper label="Сануулга хүлээн авах имэйл">
                      <Text color="indigo" fw={600}>
                        {action[1]?.payerStaff?.email || "-"}, {action[1]?.payerStaff?.lastName !== null ? action[1]?.payerStaff?.lastName[0] || "-" : "-"}.{" "}
                        {action[1]?.payerStaff?.firstName !== null ? action[1]?.payerStaff?.firstName[0] || "-" : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>

                  <Grid.Col span={4}>
                    <Input.Wrapper label="Сануулга илгээж буй">
                      <Text color="indigo" fw={600} mt={6}>
                        {user?.lastName[0] || "-"}. {user?.firstName || "-"}, {dateSecFormat(user?.createdAt)}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      label="Сануулгын төрөл"
                      placeholder="Сонгох"
                      name="reminderType"
                      options={reminderTypes.map((val) => ({ label: val.name, value: val.code }))}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      label="Сануулга зэрэглэл"
                      placeholder="Сонгох"
                      name="reminderLevel"
                      options={reminderLevels.map((val) => ({ label: val.name, value: val.code }))}
                    />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <TextField name="title" label="Сануулгын имэйлийн гарчиг:" placeholder="Оруулна уу" />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextareaField name="clientText" label="Сануулга имэйл очих текст:" placeholder="Тайлбар оруулна уу." />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <RadioGroupField
                      name="compromisable"
                      label="Төлөх амлалт өгч болох эсэх:"
                      options={productOverDueLimitTypes?.map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                      // disabled={!values.overDueLimitTerm}
                    />
                  </Grid.Col>
                </Grid>
                <Divider />
                <Flex direction="row" justify="space-between">
                  <Button variant="default" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  <Flex gap="md">
                    <Button variant="outline" type="submit">
                      Сануулга илгээх
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </>
          );
        }}
      </Form>
    </FormLayout>
  );
}
