import { Avatar, Box, Checkbox, Flex, Text, Tooltip } from "@mantine/core";
import { IconPhoto } from "@tabler/icons";
import React from "react";
import { ProgramApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { ColumnType, Table } from "../table";

export function SmeFirstsStep({ filters, setSelectedSmes }: any) {
  const [selection, setSelection] = React.useState<any[]>([]);

  const columns = useHeader({
    onChangeChecked: (checked: boolean, record: any) => {
      let selecteds = selection;

      if (checked) {
        selecteds = [...selecteds, record];
      } else {
        selecteds = selecteds.filter((s) => s.id !== record.id);
      }

      setSelection(selecteds);
      setSelectedSmes(selecteds);
    },
  });

  return <Table name="sme.program.onboarding.list" columns={columns} filters={filters} loadData={(data) => ProgramApi.smeSelect(data!)} />;
}

const useHeader = ({ onChangeChecked }: any): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
  },
  {
    title: "",
    width: "30px",
    render: (record, index) => (
      <Tooltip label={!record.isSelectable ? "Тухайн харилцагчийг сонгох боломжгүй" : "Харилцагч сонгох"}>
        <Checkbox name={`smes[${index}].checked`} onChange={(e) => onChangeChecked(e.target.checked, record)} disabled={!record.isSelectable} />
      </Tooltip>
    ),
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.regNumber}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.partnerName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.partnerRefCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес нэр",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.profileName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.refCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Нийлүүлэгчийн санхүү",
    sorter: true,
    dataIndex: "partnerName",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.finUser?.avatar !== null ? record?.finUser?.avatar : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Text size="sm" weight={500} c="#868E96">
            {record?.finUser?.lastName[0] || "-"}.{record?.finUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч орлогын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.smeInAcc?.icon !== null ? record?.smeInAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Box>
            <Text size="sm" weight={500} c="#868E96" w="max-content">
              {record?.smeInAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C" w="max-content">
              {record?.smeInAcc?.number || "-"}
            </Text>
          </Box>
        </Flex>
      );
    },
  },
  {
    title: "Нийлүүлэгч зарлагын данс",
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.smeOutAcc?.icon !== null ? record?.smeOutAcc?.icon : noimage} size={42}>
            <IconPhoto />
          </Avatar>
          <Box>
            <Text size="sm" weight={500} c="#868E96" w="max-content">
              {record?.smeOutAcc?.bankName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#44566C" w="max-content">
              {record?.smeOutAcc?.number || "-"}
            </Text>
          </Box>
        </Flex>
      );
    },
  },
];
