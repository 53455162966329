import { Button, CloseButton, Divider, Flex, Text, createStyles } from "@mantine/core";
import React from "react";
import { SettlementApi } from "../../apis";
import { ReactComponent as Logo } from "../../assets/bogd-logo.svg";
import { IUserControl } from "../../interfaces/IUserControl";
import { tugrug } from "../../utils";
import { dateFormat, dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";

export function CollectionPaymentSettlementForm({ action, onCancel, reload, date }: { action: any; reload: any; date: any; onCancel: () => void }) {
  const actionData = action && action[0] === "page" && [action && action[1]];
  const { classes } = useStyles();
  const nowMoment = new Date();
  const [loading, setLoading] = React.useState<boolean>(false);

  const columns = useHeader({
    onClick: (key) => {
      switch (key) {
        case "edit": {
          break;
        }
      }
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      await SettlementApi.send(action[1]?.id, {
        month: date === undefined ? dateFormat(nowMoment) : dateFormat(date),
        businessId: action[1]?.business?.id,
      });
      reload();
      onCancel && onCancel();
      Message.success("Амжилттай тооцооны хуудас илгээсэн.");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout
      title={`${action[1]?.business?.refCode}, ${action[1]?.business?.profileName}`}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <Divider mb="lg" />
      <Flex justify="space-between" direction="row">
        <Logo />
        <Flex direction="column" align="flex-end">
          <Text className={classes.p2}>ТТД: {action[1]?.business?.regNumber || "-"}</Text>
          <Text className={classes.p1}>Санхүүжилтийн тооцооны хуудас</Text>
        </Flex>
      </Flex>
      <Divider mb="lg" mt="lg" />
      <Flex gap="md" align="center" direction="column" wrap="wrap">
        <Text>
          <span className={classes.p1}>Хүндэт</span>{" "}
          <span className={classes.p2}>{action[1]?.financeStaff?.lastName !== null ? action[1]?.financeStaff?.lastName[0] : "-"}.</span>{" "}
          <span className={classes.p2}>{action[1]?.financeStaff?.firstName || "-"}</span>
        </Text>
        <Text mt="lg">
          <span className={classes.p3}>Таны төлөөлж буй</span>{" "}
          <span className={classes.p4}>
            {action[1]?.business?.partner?.businessName || "-"}, {action[1]?.business?.partner?.refCode || "-"}
          </span>{" "}
          <span className={classes.p3}>-ийн</span>{" "}
          <span className={classes.p4}>
            {action[1]?.business?.profileName || "-"}, {action[1]?.business?.refCode || "-"}
          </span>
          <span className={classes.p3}>- ийн хувьд</span>
        </Text>
        <Text>
          <span className={classes.p4}>{date === undefined ? dateFormat(nowMoment) : dateFormat(date)}</span>{" "}
          <span className={classes.p3}>-ийн байдлаар санхүүжилт эргэн төлөх</span> <span className={classes.p2}>{action[1]?.lastInvCount}</span>{" "}
          <span className={classes.p3}>тооны</span>
        </Text>
        <Text>
          <span className={classes.p3}>нийт</span> <span className={classes.p2}>{tugrug(action[1]?.lastAmount)}</span>{" "}
          <span className={classes.p3}>төгрөгийн үлдэгдэл байна.</span>{" "}
        </Text>

        <Text mt="lg">
          <span className={classes.p3}>Энэхүү үлдэгдэлийг хугацаандаа төлсөн тохиолдолд торгууль алданги тооцохгүй нөхцөлтэйг сануулж байна.</span>
        </Text>
      </Flex>
      <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap" mt="sm">
        <Button variant="outline" color="violet">
          Дэлгэрэнгүй
        </Button>
        <Button variant="filled" color="violet" onClick={() => onSubmit()} loading={loading}>
          Тооцоог илгээх
        </Button>
      </Flex>
      <Divider mb="lg" mt="lg" />

      <Table name="collection.settlemen1t.list" columns={columns} pagination={false} dataSource={actionData} />
      <Divider mb="lg" mt="lg" />
      <Flex mih={50} gap="sm" justify="flex-end" align="flex-end" direction="column" wrap="wrap">
        <Text className={classes.p1}>Эхний үлдэгдэл: {tugrug(action[1]?.firstAmount || 0)}</Text>
        <Text className={classes.p3}>Тухайн сард нэмэгдсэн: {tugrug(action[1]?.confirmedAmount || 0)}</Text>
        <Text className={classes.p3}>Тухайн сард хасагдсан: {tugrug(action[1]?.paidAmount || 0)}</Text>
        <Text className={classes.p1}>Эцсийн үлдэгдэл: {tugrug(action[1]?.lastAmount || 0)}</Text>
      </Flex>
    </FormLayout>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IUserControl) => void;
};

const useHeader = ({ onClick }: HeaderProps): ColumnType<IUserControl>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Огноо, цаг",
    dataIndex: "image",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "image",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {"Талбар"}
        </Text>
      );
    },
  },
  {
    title: "Үнийн дүн",
    dataIndex: "image",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {tugrug(12000)}
        </Text>
      );
    },
  },
  {
    title: "Үлдэгдэл дүн",
    dataIndex: "image",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {tugrug(25000)}
        </Text>
      );
    },
  },
];

const useStyles = createStyles((theme) => ({
  p1: {
    color: "#46566a",
    fontWeight: 700,
  },
  p2: {
    color: "#6e61e7",
    fontWeight: 700,
  },
  p3: {
    color: "#46566a",
    fontWeight: 500,
  },
  p4: {
    color: "#6e61e7",
    fontWeight: 500,
  },
  p5: {
    color: "#6e61e7",
  },
}));
