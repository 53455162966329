import { ActionIcon, Badge, Button, Divider, Grid, Group, Input, Select, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconListDetails, IconX } from "@tabler/icons";
import React, { useId } from "react";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSwr from "swr";
import { RequestApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { ISupplier } from "../../interfaces/ISupplier";
import { tugrug } from "../../utils";
import { ColumnType, Table } from "../table";

export function ScfSupplierList() {
  const navigate = useNavigate();
  const id = useId();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);
  const [business, setBusiness] = React.useState("");
  const [debouncedBusiness] = useDebouncedValue(business, 600);
  const [program, setProgram] = React.useState("");
  const [debouncedProgram] = useDebouncedValue(program, 600);

  const columns = useHeader({
    user,
    onClick: (key, record) => {
      switch (key) {
        case "request": {
          navigate(`/scf/request/SUPPLIER/${record.id}/1`);
          break;
        }
      }
    },
  });

  const onChangeReg = (e: string) => {
    if (e.length === 7 || e.length === 0) {
      setRegNumber(e);
    }
  };

  const { data } = useSwr(
    `/api/scf/request/supplier_led/business_select`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0) {
        let res = await RequestApi.business("");
        return res;
      }
      return [];
    },
    {},
  );

  const { data: programData } = useSwr(
    `/api/scf/request/supplier_led/program_select/`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0) {
        let res = await RequestApi.program("");
        return res;
      }
      return [];
    },
    {},
  );

  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <Input.Wrapper
            id={id}
            onChange={(e: any) => {
              onChangeReg(e.target.value);
            }}>
            <Input<any>
              component={IMaskInput}
              mask="0000000"
              id={id}
              iconWidth={80}
              placeholder="хайх"
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"ТТД"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
            />
          </Input.Wrapper>
        </Grid.Col>
        {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
          <Grid.Col span={3}>
            <Select
              value={business}
              data={(data || []).map((c: any) => ({
                value: c.id,
                label: c.profileName,
              }))}
              label=""
              iconWidth={150}
              placeholder="сонгох "
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"Бизнесийн нэр"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              onChange={(e) => {
                setBusiness(e as string);
              }}
              rightSection={
                <ActionIcon onClick={() => setBusiness("")}>
                  <IconX size={15} stroke={1.2} />
                </ActionIcon>
              }
            />
          </Grid.Col>
        )}
        {(user?.permissions || []).filter((c) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView).length > 0 && (
          <Grid.Col span={3}>
            <Select
              value={program}
              data={(programData || []).map((c: any) => ({
                value: c.id,
                label: c.name,
              }))}
              label=""
              iconWidth={160}
              placeholder="сонгох "
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"Хөтөлбөрийн нэр"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              onChange={(e) => {
                setProgram(e as string);
              }}
              rightSection={
                <ActionIcon onClick={() => setProgram("")}>
                  <IconX size={15} stroke={1.2} />
                </ActionIcon>
              }
            />
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <Divider mb={5} />
        </Grid.Col>
      </Grid>

      <Table
        name="scf.funding.supplier.list"
        columns={columns}
        filters={{
          programId: debouncedProgram,
          businessId: debouncedBusiness,
          businessRegNumber: debouncedRegNumber,
        }}
        loadData={(data) => RequestApi.listSupplier(data!)}
      />
    </>
  );
}

const useHeader = ({ onClick, user }: { onClick: (key: string, record: ISupplier) => void; user: any }): ColumnType<ISupplier>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Хүсэлтүүд" position="bottom">
        <Button
          variant="light"
          radius={100}
          w={35}
          h={35}
          p={0}
          onClick={() =>
            onClick(
              user?.permissions.some((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView) ? "request" : "",
              record,
            )
          }
          disabled={!user?.permissions.some((c: IPermissions) => c.module === "SCF" && c.code === "SCF_REQUEST_MANAGEMENT_SL" && c.isView)}>
          <IconListDetails />
        </Button>
      </Tooltip>
    ),
  },
  {
    title: "Хүсэлтийн код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record?.requestRefCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хүсэлт илгээсэн бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.requestedBusiness?.refCode || "-"}, {record?.requestedBusiness?.regNumber || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.requestedBusiness?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Хөтөлбөрийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.name || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.anchor?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#317169" w="max-content">
            {record?.anchor?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Анкор бизнес",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.anchor?.businessName || "-"}
          </Text>
          <Badge size="md" radius="sm" variant="outline">
            {record?.anchor?.refCode || "-"}
          </Badge>
        </div>
      );
    },
  },
  {
    title: "Ашигласан лимит дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.utilizedAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Ашиглах боломжтой",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.availableAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлтийн дүн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.requestedAmount || 0)}
          </Text>
        </Group>
      );
    },
  },
  {
    title: "Хүсэлт тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.requestCount || "0"}
        </Text>
      );
    },
  },
  {
    title: "Нэхэмжлэх тоо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {record.invCount || "0"}
        </Text>
      );
    },
  },
  {
    title: "Батлагдсан лимит",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group position="right">
          <Text size="sm" weight={500} c="#44566C">
            {tugrug(record.programLimit || 0)}
          </Text>
        </Group>
      );
    },
  },
];
