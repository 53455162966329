import React from "react";
import { Avatar, Badge, Button, Checkbox, Flex, Group, Modal, Paper, Text, Tooltip } from "@mantine/core";
import { BankAccountApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { ColumnType, Table, ITableRef } from "../../../components/table";
import { IBankAccount } from "../../../interfaces/IBankAccount";
import { dateSecFormat } from "../../../utils/date";
import { BankAccountForm } from "../../../components/account-settings/form";
import { AccountSettingsPinForm } from "../../../components/account-settings/pin-form";
import { IconInfoSquare } from "@tabler/icons";
import noimage from "../../../assets/no-image.png";
import { IGeneral } from "../../../interfaces/IGeneral";
import { useSelector } from "react-redux";

export function CustomerBankAccount() {
  const ref = React.useRef<ITableRef>(null);
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const [change, setChange] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<IBankAccount | null>();
  const { currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const [filters] = React.useState({ query: "" });

  const isActive = (val: boolean) => {
    switch (val) {
      case true: {
        return (
          <Badge variant="outline" size="md" color="green.4" radius="sm">
            Тийм
          </Badge>
        );
      }
      case false: {
        return (
          <Badge variant="outline" size="md" color="red.4" radius="sm">
            Үгүй
          </Badge>
        );
      }
      default: {
        return (
          <Badge variant="outline" size="md" radius="sm">
            {"-"}
          </Badge>
        );
      }
    }
  };

  const columns = useHeader({
    isActive,
    currencies,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          setAction(["detail", item as any]);
          break;
        }

        case "confirm": {
          setAction(["confirm", item as any]);
          break;
        }
        case "reject": {
          setAction(["reject", item as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
    setChange([]);
  };

  const reloadTable = () => {
    ref?.current?.reload();
  };

  return (
    <>
      <PageLayout title="B2B Данс жагсаалт" subTitle="Finance системийн эрх авсан харилцагчдын платформд бүртгэсэн дансны жагсаалт" breadcrumb={breadcrumbs}>
        <Paper radius="sm" p="md" withBorder>
          <Table ref={ref} name="b2b.customer.account.list" columns={columns} filters={filters} loadData={(data) => BankAccountApi.list(data!)} />
        </Paper>
      </PageLayout>
      <Modal opened={action[0] === "detail"} onClose={() => setAction([])} withCloseButton={false} size="60%" centered>
        <BankAccountForm onCancel={onCancel} action={action} setAction={setAction} payload={payload} reload={reloadTable} setChange={setChange} />
      </Modal>
      <Modal
        opened={action[0] === "confirm" || action[0] === "reject" || action[0] === "pin"}
        onClose={() => setAction([])}
        size={"md"}
        withCloseButton={false}
        centered>
        <AccountSettingsPinForm
          change={change}
          action={action}
          reload={reloadTable}
          onCancel={() => setAction([])}
          title="Баталгаажуулах пин код"
          subTitle="Мэдээллээ сайтар нягтлан ПИН код оруулна уу."
        />
      </Modal>
    </>
  );
}

const useHeader = ({
  onClick,
  isActive,
  currencies,
}: {
  onClick: (key: string, record: IBankAccount) => void;
  currencies: any;
  isActive: (key: boolean) => JSX.Element;
}): ColumnType<IBankAccount>[] => [
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex gap="sm">
          {!record.isVerified && !record.isActive ? (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
                <IconInfoSquare />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Дэлгэрэнгүй" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0}>
                <IconInfoSquare stroke={0.4} />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record?.business.logo !== null ? record?.business.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Банк",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge color="indigo" size="md" radius="sm" variant="outline">
          {record?.bankName || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 150 }}>
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.business?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.partner?.refCode || "-"}
          </Text>
          <Text size="sm" weight={500} c="#141517" w="max-content">
            {record?.partner?.businessName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <div>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.business?.regNumber || "-"}
          </Text>
          <Text size="sm" weight={500} c="#141517" w="max-content">
            {record?.business?.profileName || "-"}
          </Text>
        </div>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline">
          {currencies.find((c: any) => c.code === record.currency)?.name || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Үндсэн эсэх",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isDefault}
          label={
            record.isDefault ? (
              <Text size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Банк баталгаажилт",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Checkbox
          checked={record.isVerified}
          label={
            record.isVerified ? (
              <Text size="sm" weight={500} c="#44566C">
                {"Тийм"}
              </Text>
            ) : (
              <Text size="sm" weight={500} c="#44566C">
                {"Үгүй"}
              </Text>
            )
          }
          onChange={() => {}}
        />
      );
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return isActive(record.isActive);
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Group noWrap>
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} alt="" color="indigo" />
          <div>
            <Text size="sm" weight={500} c="#44566C" w="max-content">
              {record?.regUser?.lastName[0] || "-"}.{record?.regUser?.firstName || "-"}
            </Text>
            <Text size="sm" weight={500} c="#141517" w="max-content">
              {record?.regUser?.email}
            </Text>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.createdAt)}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Харилцагч удирдлага",
  },
  {
    label: "B2B Данс",
  },
];
