import { Avatar, Flex, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { ObservationApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReminder } from "../../interfaces/IReminder";
import { IReference } from "../../models/General";
import { ColumnType, Table } from "../table";

type Props = {
  action: any;
};

export function PaymentObservationMailList({ action }: Props) {
  const { reminderConnectTypes, reminderTypes, reminderLevels, reminderResponses, reminderResults } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const [filters] = React.useState({ repaymentId: action && action[1]?.id, isEmail: `${true}` });

  const columns = useHeader({
    reminderConnectTypes,
    reminderTypes,
    reminderLevels,
    reminderResponses,
    reminderResults,
  });

  return <Table name="collection-observation-mail.list" columns={columns} filters={filters} loadData={(data) => ObservationApi.reminder(data!)} />;
}

const useHeader = ({
  reminderConnectTypes,
  reminderTypes,
  reminderLevels,
  reminderResponses,
  reminderResults,
}: {
  reminderConnectTypes: any;
  reminderTypes: any;
  reminderLevels: any;
  reminderResponses: any;
  reminderResults: any;
}): ColumnType<IReminder>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
  },
  {
    title: "Холбогдсон төрөл",
    dataIndex: "connectType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {reminderConnectTypes.find((item2: IReference) => item2.code === record.connectType)?.name}
        </Text>
      );
    },
  },
  {
    title: "Сануулгын төрөл",
    dataIndex: "reminderType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {reminderTypes.find((item2: IReference) => item2?.code === record?.reminderType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сануулга зэрэглэл",
    dataIndex: "reminderLevel",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {reminderLevels.find((item2: IReference) => item2?.code === record?.reminderLevel)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Амлалтын хариу",
    dataIndex: "response",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {reminderResponses.find((item2: IReference) => item2?.code === record?.response)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Харилцагчид очих тэмдэглэл",
    dataIndex: "clientText",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {record.clientText || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дотоод тэмдэглэл",
    dataIndex: "internalText",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" style={{ width: 200, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {record.internalText || "-"}
        </Text>
      );
    },
  },
  {
    title: "Холбогдсон үр дүн",
    dataIndex: "result",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {reminderResults.find((item2: IReference) => item2?.code === record?.result)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Холбоо барьсан",
    dataIndex: "",
    render: (record) => {
      return (
        <Flex align="center" gap="xs">
          <Avatar src={record?.remindedUser?.avatar !== null && record?.remindedUser !== null ? record?.remindedUser?.avatar : noimage} />
          <Text size="sm" weight={500} c="#44566C">
            {record?.remindedUser?.lastName[0] || "-"}.{record?.remindedUser?.firstName || "-"}
          </Text>
        </Flex>
      );
    },
  },
];
